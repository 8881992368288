<template>
  <div class="one-profile-step">
    <div class="with-side-and-bottom-padding">
      <h3 class="adult-title" v-html="getTranslation('adult_modal_one_profile_title')" />
      <div class="body1" v-html="getTranslation('adult_modal_one_profile')" />
      <div class="buttons-wrapper mb-16">
        <ButtonDefault @click="enter">
          <span v-html="getTranslation('enter_profile')" />
        </ButtonDefault>
        <ButtonDefault view="secondary" @click="disablePin">
          <span v-html="getTranslation('disable_pin')" />
        </ButtonDefault>
      </div>
      <a
        class="link action-colored"
        @click="seeChangePin"
        v-html="getTranslation('see_change_pin')"
      />
      <div
        class="body1 hint color-secondary mt-32"
        v-html="getTranslation('adult_modal_one_profile_hint')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import { actions } from 'src/store/actions';
import { ADULT_ONBOARDING_STEPS } from 'src/constants';

@Component({
  components: { ButtonDefault, ProgressBar },
})
export default class OneProfile extends SequoiaComponent {
  enter() {
    actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.EnterPin);
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_one_profile enter_profile' });
  }
  async disablePin() {
    actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.VerifySession);
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_one_profile disable_pin' });
  }
  seeChangePin() {
    actions.adultOnboarding.setNextStep(this.$store, ADULT_ONBOARDING_STEPS.YourPin);
    actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.VerifySession);
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_one_profile see_change_pin' });
  }
}
</script>

<template>
  <div class="no-profile-step">
    <ImageWithHash
      class="background-desktop"
      src="/public/images/backgrounds/grapefruit-gradient.png"
      alt=""
    />
    <div class="with-side-and-bottom-padding content">
      <h3 class="title" v-html="getTranslation('adult_modal_no_profile_title')" />
      <div class="body1" v-html="getTranslation('adult_modal_no_profile')" />
      <div class="buttons-wrapper">
        <ButtonDefault @click="create">
          <span v-html="getTranslation('create')" />
        </ButtonDefault>
        <ButtonDefault view="secondary" type="button" @click="cancel">
          <span v-html="getTranslation('cancel')" />
        </ButtonDefault>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import { actions } from 'src/store/actions';
import { ADULT_ONBOARDING_PIN_ACTION, ADULT_ONBOARDING_STEPS } from 'src/constants';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash, ButtonDefault, ProgressBar },
})
export default class NoProfile extends SequoiaComponent {
  create() {
    actions.adultOnboarding.setNextStep(this.$store, ADULT_ONBOARDING_STEPS.SetPin);
    actions.adultOnboarding.setPinAction(this.$store, ADULT_ONBOARDING_PIN_ACTION.create);
    actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.VerifySession);
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_no_profile create' });
  }
  cancel() {
    this.$emit('close');
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_no_profile cancel' });
  }
}
</script>

<style lang="scss" scoped></style>

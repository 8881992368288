<template>
  <section
    v-if="queries && queries.length"
    class="popular-queries pb-48 pb-tablet-32 pb-mobile-32"
    data-cy="popular-queries"
  >
    <h3 v-html="getTranslation('gsearch_popular_searches')" />
    <div class="tags">
      <TagSequoia
        v-for="(query, i) in queries"
        :key="query._id"
        :text="getTranslation(query.titleId)"
        :data-cy="`popular-query-${i}`"
        @click="onClick(getTranslation(query.titleId))"
      />
    </div>
  </section>
</template>
s
<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import TagSequoia from 'src/components/ui/TagSequoia.vue';
import { actions } from 'src/store/actions';

@Component({
  components: {
    TagSequoia,
  },
})
export default class PopularQueries extends SequoiaComponent {
  get queries() {
    return selectors.siteConfig.globalSearchPopularQueriesSelector(this.$store);
  }

  onClick(query: string) {
    actions.search.setQuery(this.$store, query);
    this.$emit('submitSearch', 'Популярный поиск');
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/tags';
</style>

<template>
  <div class="enter-pin">
    <ImageWithHash
      class="background-desktop"
      src="/public/images/backgrounds/avocado-gradient.png"
    />
    <div class="with-side-and-bottom-padding content">
      <h3 class="title" v-html="getTranslation('adult_modal_enter_pin_title')" />
      <div class="body1 mb-24" v-html="getTranslation('adult_modal_enter_pin')" />
      <div class="input-wrapper">
        <InputText
          v-model="pin"
          icon="visibility"
          input-category="floating"
          autocomplete="off"
          size="small"
          :tab-index-for-button="-1"
          :maxlength="4"
          :allow-digits-only="true"
          :label="getTranslation('enter_pin')"
          :description="getTranslation('enter_4_characters')"
          class="mr-8"
        />
        <ButtonDefault :disabled="pin.length !== PROFILE_PIN_LENGTH" class="button" @click="next">
          <span v-html="getTranslation('next')" />
        </ButtonDefault>
      </div>
      <p class="body2 color-light-error mt-4" v-text="error" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { PROFILE_PIN_LENGTH } from 'src/constants';
import InputText from 'src/components/ui/input/InputText.vue';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash, ButtonDefault, InputText },
})
export default class EnterPin extends SequoiaComponent {
  PROFILE_PIN_LENGTH = PROFILE_PIN_LENGTH;
  pin = '';
  error = '';

  get adultProfile() {
    return selectors.adultOnboarding.adultProfileSelector(this.$store);
  }

  async next() {
    try {
      this.$emit('set-loader', true);
      await actions.profile.change(this.$store, {
        data: {
          Id: this.adultProfile.id,
          Pin: this.pin,
        },
      });
      this.$emit('set-loader', false);

      this.$emit(
        'goToChannelPageWithAlert',
        this.getTranslation('adult_alert_profile_manage_title'),
        this.getTranslation('adult_alert_profile_manage')
      );
    } catch (err) {
      this.error = err.message;
    }
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_enter_pin' });
  }
}
</script>

<style lang="scss" scoped>
.enter-pin {
  .input-wrapper {
    display: flex;
    max-width: 288px;
  }
}
</style>

<template>
  <div class="create-profile-step">
    <ImageWithHash
      class="background-desktop"
      src="/public/images/backgrounds/avocado-gradient.png"
      alt=""
    />
    <div class="with-side-and-bottom-padding content">
      <h3 class="title" v-html="getTranslation('adult_modal_set_new_pin_title')" />
      <div class="body1 mb-24" v-html="getTranslation('adult_modal_set_new_pin')" />
      <div class="input-wrapper mb-32">
        <InputText
          v-model="pin"
          icon="visibility"
          input-category="floating"
          autocomplete="off"
          size="small"
          :tab-index-for-button="-1"
          :maxlength="4"
          :allow-digits-only="true"
          :label="getTranslation('enter_pin')"
          :description="getTranslation('enter_4_characters')"
          class="mr-8"
        />
        <ButtonDefault :disabled="pin.length !== PROFILE_PIN_LENGTH" class="button" @click="next">
          <span v-html="getTranslation('next')" />
        </ButtonDefault>
      </div>
      <a
        class="link action-colored"
        @click="dontNeedPin"
        v-html="getTranslation('adult_modal_dont_need_pin')"
      />
      <p class="body2 color-light-error mt-4" v-text="error" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import { actions } from 'src/store/actions';
import InputText from 'src/components/ui/input/InputText.vue';
import * as api from 'src/api';
import logger from 'src/utils/logger';
import { HTTP_CODES, PROFILE_PIN_LENGTH } from 'src/constants';
import { selectors } from 'src/store/selectors';
import {
  ADULT_ONBOARDING_PIN_ACTION,
  ADULT_ONBOARDING_PROFILE_CHANGE_TIMEOUT,
  ADULT_ONBOARDING_MAX_NUMBER_OF_TRY,
} from 'src/constants';
import { sleep } from 'src/utils';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

const log = logger('adult-create-profile');

@Component({
  components: { ButtonDefault, ProgressBar, InputText, ImageWithHash },
})
export default class SetPin extends SequoiaComponent {
  PROFILE_PIN_LENGTH = PROFILE_PIN_LENGTH;
  pin = '';

  counter = 0;
  maxNumberOfTry = ADULT_ONBOARDING_MAX_NUMBER_OF_TRY;
  error = '';

  get adultProfile() {
    return selectors.adultOnboarding.adultProfileSelector(this.$store);
  }

  get action() {
    return selectors.adultOnboarding.pinActionSelector(this.$store);
  }

  async next() {
    const data = { Name: 'Для взрослых', Pin: this.pin, 'Restriction.Id': 'adult' };

    try {
      this.$emit('set-loader', true);
      if (this.action === ADULT_ONBOARDING_PIN_ACTION.create) {
        actions.adultOnboarding.setPinAction(this.$store, '');

        await api.profile.create({
          data,
        });

        this.gaEvent({ category: 'adult_profile', action: 'adult_modal_set_pin' });
      } else {
        await actions.profile.update(this.$store, this.adultProfile.id, {
          data,
        });

        this.gaEvent({ category: 'adult_profile', action: 'adult_modal_set_new_pin' });
      }
      await actions.profile.getAccount(this.$store);

      await this.changeProfile(this.adultProfile.id, this.pin);
      this.$emit('set-loader', false);
    } catch (err) {
      log.error(err);
    }
  }

  async changeProfile(id: string, pin = '') {
    try {
      await actions.profile.change(this.$store, {
        data: {
          Id: id,
          Pin: pin,
        },
      });

      await this.goToChannelPageWithAlert();
    } catch (err) {
      if (err.code === HTTP_CODES.BAD_REQUEST && this.counter < this.maxNumberOfTry) {
        this.counter++;
        await sleep(ADULT_ONBOARDING_PROFILE_CHANGE_TIMEOUT);
        await this.changeProfile(id, pin);
      }
      if (this.counter === this.maxNumberOfTry) {
        this.error = this.getTranslation('adult_onboarding_bad_request');
      }
    }
  }

  async dontNeedPin() {
    const data = { Name: 'Для взрослых', Pin: '', 'Restriction.Id': 'adult' };

    try {
      this.$emit('set-loader', true);
      if (this.action === ADULT_ONBOARDING_PIN_ACTION.create) {
        await api.profile.create({
          data,
        });
      } else {
        await actions.profile.update(this.$store, this.adultProfile.id, {
          data,
        });
      }
      await actions.profile.getAccount(this.$store);
      await this.changeProfile(this.adultProfile.id, '');
      this.$emit('set-loader', false);
    } catch (err) {
      log.error(err);
    }

    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_set_pin dont_need_pin' });
  }

  async goToChannelPageWithAlert() {
    if (this.action === ADULT_ONBOARDING_PIN_ACTION.create) {
      this.$emit(
        'goToChannelPageWithAlert',
        this.getTranslation('adult_alert_profile_created_title'),
        this.getTranslation('adult_alert_profile_created')
      );
    } else {
      this.$emit(
        'goToChannelPageWithAlert',
        this.getTranslation('adult_alert_profile_manage_title'),
        this.getTranslation('adult_alert_profile_manage')
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.create-profile-step {
  .input-wrapper {
    display: flex;
    max-width: 288px;
  }
}
</style>

<template>
  <div class="home-page">
    <div class="home-page-inner">
      <LoaderSpinner v-if="$store.common.isProfileLoading" />

      <template v-else>
        <HomeBanners
          class="pb-64 pb-tablet-48 pb-mobile-48"
          @callAdultOnboardingModal="callAdultOnboardingModal"
        />

        <div class="container-1440">
          <!-- change blocks order for kt -->
          <SuggestedChannels
            v-if="
              channelCollectionsDataStateLoaded && !hasChannelCollectionsToShow && wlDetector().isKt
            "
            class="pb-48 pb-tablet-32 pb-mobile-32"
          />

          <!-- History -->
          <HomeHistory
            v-if="!isAnonymous"
            :key="refreshKey"
            class="pb-48 pb-tablet-32 pb-mobile-32"
          />

          <!-- Suggested channels -->
          <SuggestedChannels
            v-if="
              channelCollectionsDataStateLoaded &&
              !hasChannelCollectionsToShow &&
              !wlDetector().isKt
            "
            class="pb-48 pb-tablet-32 pb-mobile-32"
          />

          <!-- Recent channels -->
          <RecentChannels v-if="!isAnonymous" class="pb-48 pb-tablet-32 pb-mobile-32" />

          <!-- VOD -->
          <TilesVodNavigationGroup
            class="with-side-padding pb-48 pb-tablet-32 pb-mobile-16"
            data-cy="home"
            :show-header="false"
            @gaTileClick="gaTileClick"
          />

          <!-- Channel Collections -->
          <ChannelCollections
            v-if="channelCollectionsDataStateLoaded && hasChannelCollectionsToShow"
            class="pb-48 pb-tablet-32 pb-mobile-32"
          />

          <!-- Featured Channels -->
          <FeaturedChannels
            v-else
            class="pb-64 pb-tablet-48 pb-mobile-48"
            @gaViewMore="gaViewMore"
            @gaFeaturedClick="gaFeaturedClick"
            @gaScroll="gaScroll"
          />

          <!-- VOD Collections -->
          <VodCollections v-if="showVodCollections" />

          <!-- VOD Recommendations -->
          <VodRecommendations v-else gaEventCategory="homescreen" />

          <!-- Channel Collection & Channel details-->
          <ModalSequoia
            v-if="isModalOpen && (currentChannelCollection || isModalChannelOpen)"
            type="responsive"
            theme-forced="dark"
            :show-title="false"
            :title="modalChannelAndCollectionTitle"
            :with-button-back="showButtonBackInModalChannelAndCollection"
            @close="hideModalChannelAndCollection"
            @back="handleModalChannelAndCollectionStepBack"
          >
            <ChannelCollectionDetails
              v-if="!isModalChannelOpen"
              :collection="currentChannelCollection"
            />
            <ChannelDetails v-if="isModalChannelOpen" :channel="modalChannel" />
          </ModalSequoia>

          <!-- VOD Collection -->
          <ModalSequoia
            v-if="isModalOpen && currentVodCollection"
            type="responsive"
            theme-forced="dark"
            :show-title="false"
            :title="currentVodCollection.caption"
            @close="hideModalVodCollection"
          >
            <VodCollectionDetails :collection="currentVodCollection" />
          </ModalSequoia>
        </div>
      </template>

      <!-- Adult onboarding -->
      <ModalSequoia
        v-if="showOnboarding"
        type="fixed"
        size="medium"
        @close="showOnboarding = false"
      >
        <AdultModal @close="showOnboarding = false" />
      </ModalSequoia>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { wlDetector } from 'src/utils';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import FeaturedChannels from 'src/components/ui/FeaturedChannels.vue';
import VodRecommendations from 'src/components/vod/VodRecommendations.vue';
import VodCollections from 'src/components/vod/VodCollections.vue';
import VodCollectionDetails from 'src/components/vod/VodCollectionDetails.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import HomeBanners from 'src/components/home/HomeBanners.vue';
import HomeHistory from 'src/components/home/HomeHistory.vue';
import SuggestedChannels from 'src/components/home/SuggestedChannels.vue';
import RecentChannels from 'src/components/home/RecentChannels.vue';
import TilesVodNavigationGroup from 'src/components/ui/tiles/TilesVodNavigationGroup.vue';
import AdultModal from 'src/components/adult-onboarding/AdultModal.vue';
import ChannelCollections from 'src/components/home/ChannelCollections.vue';
import ChannelCollectionDetails from 'src/components/tv-channels/ChannelCollectionDetails.vue';
import ChannelDetails from 'src/components/tv-channels/ChannelDetails.vue';
import { getChannelTitle } from 'src/utils/channel';
import { makePath } from 'src/utils/url';
// import logger from 'src/utils/logger';

// const log = logger('home-page');

@Component({
  components: {
    ChannelDetails,
    ChannelCollectionDetails,
    ChannelCollections,
    LoaderSpinner,
    VodRecommendations,
    VodCollections,
    VodCollectionDetails,
    ModalSequoia,
    HomeBanners,
    HomeHistory,
    SuggestedChannels,
    RecentChannels,
    TilesVodNavigationGroup,
    FeaturedChannels,
    AdultModal,
  },
})
export default class HomePage extends SequoiaComponent {
  wlDetector = wlDetector;
  getChannelTitle = getChannelTitle;

  isBannersAndTvDataLoaded = false;
  showOnboarding = false;
  refreshKey = 0;

  @Watch('isAnonymous')
  async onIsAnonymousChange() {
    await actions.home.loadBanners(this.$store);
  }

  @Watch('$store.vod.states.sourcesLoaded')
  onSourcesLoaded(val: boolean) {
    if (val) {
      this.refreshKey++;
    }
  }

  @Watch('$store.common.isProfileLoaded')
  async onIsProfileLoaded(val: boolean) {
    if (val) {
      const scrollWrap = document.querySelector('.scroll-layout-wrap');

      if (scrollWrap) {
        scrollWrap.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  @Watch('$store.common.currentChannelCollectionSlug')
  onCurrentChannelCollectionSlugChange(slug: string) {
    if (slug) {
      actions.common.showModal(this.$store);
    }
  }

  @Watch('$store.vod.currentCollectionId')
  onCurrentVodCollectionIdChange(currentCollectionId: string) {
    if (currentCollectionId) {
      actions.common.showModal(this.$store);
    } else {
      actions.common.hideModal(this.$store);
      history.pushState({}, '', makePath(`/`));
    }
  }

  @Watch('isBannersAndTvDataLoaded')
  onIsBannersAndTvDataLoadedChange(loaded: boolean) {
    actions.common[loaded ? 'showFooter' : 'hideFooter'](this.$store);
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get isModalChannelOpen() {
    return selectors.tvChannels.isModalChannelOpenSelector(this.$store);
  }

  get openChannelId() {
    return selectors.tvChannels.openChannelIdSelector(this.$store);
  }

  get modalChannelAndCollectionTitle() {
    return this.isModalChannelOpen
      ? getChannelTitle(this.modalChannel)
      : this.getByLanguage(this.currentChannelCollection?.title) || '';
  }

  get modalChannel() {
    return this.getChannelById(this.openChannelId);
  }

  get channels() {
    return selectors.tvChannels.allChannelsSelector(this.$store);
  }

  get channelCollectionsToShow() {
    return selectors.channelCollections.channelCollectionsToShowOnMainSelector(this.$store);
  }

  get hasChannelCollectionsToShow() {
    return !!this.channelCollectionsToShow.length;
  }

  get channelCollectionsDataStateLoaded() {
    return selectors.common.channelCollectionsDataStateLoadedSelector(this.$store);
  }

  get currentChannelCollection() {
    return selectors.channelCollections.currentCollectionSelector(this.$store);
  }

  get showVodCollections() {
    return selectors.vod.showVodCollectionsSelector(this.$store);
  }

  get currentVodCollection() {
    return selectors.vod.currentCollectionSelector(this.$store);
  }

  get openChannelIdsHistory() {
    return selectors.tvChannels.openChannelIdsHistorySelector(this.$store);
  }

  get showButtonBackInModalChannelAndCollection() {
    return (
      (!!this.currentChannelCollection && !!this.openChannelIdsHistory.length) ||
      (!this.currentChannelCollection && this.openChannelIdsHistory.length > 1)
    );
  }

  async serverPrefetch() {
    actions.seo.addBreadcrumbs(this.$store, [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      {
        name: this.getTranslation('breadcrumb_homepage_30_days'),
        link: '/#',
        separator: `⚠️`,
      },
    ]);

    await actions.home.loadBanners(this.$store).catch(() => {
      // do nothing
    });
    await this.loadTvData();
  }

  async mounted() {
    if (this.$route.path === '/adult') {
      this.callAdultOnboardingModal();
    }

    await actions.home.loadBanners(this.$store).catch(() => {
      // do nothing
    });
    await this.loadTvData();

    await actions.tvChannels.loadRecentAndFavChannels(this.$store);

    this.isBannersAndTvDataLoaded = true;

    this.gaEvent({ action: 'Показ страницы', category: 'homescreen' });
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  async loadTvData() {
    await actions.tvChannels.loadTvData(this.$store);
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  hideModalVodCollection() {
    actions.vod.setCurrentCollectionId(this.$store, '');
  }

  hideModalChannelAndCollection() {
    actions.channelCollections.setCurrentCollectionSlug(this.$store, '');
    this.hideModalChannel();
    actions.common.hideModal(this.$store);
    history.pushState({}, '', makePath(`/`));
  }

  handleModalChannelAndCollectionStepBack() {
    const arrayOfChannelIds: string[] = this.openChannelIdsHistory;
    const currentChannelId: string = arrayOfChannelIds[arrayOfChannelIds.length - 2];

    if (!currentChannelId) {
      actions.tvChannels.setModalChannelId(this.$store, '');
      this.$store.tvChannels.openChannelIdsHistory = [];
      this.$store.tvChannels.isModalChannelOpen = false;
      history.pushState(
        {},
        '',
        makePath(`/collection/channels/${this.currentChannelCollection?.slug}`)
      );
    } else {
      actions.tvChannels.handleModalStepBack(this.$store, currentChannelId);
      history.pushState({}, '', makePath(`/${currentChannelId}`));
      document.querySelector('.modal')?.scrollTo(0, 0);
    }
  }

  hideModalChannel() {
    actions.tvChannels.hideChannelDetails(this.$store);
  }

  gaTileClick(vod: string | undefined) {
    this.gaEvent({
      category: 'homescreen',
      action: 'Клик по тайтлу в стрипе',
      strip_name: 'Кинотеатр',
      vod_name: vod,
    });
  }

  gaViewMore() {
    this.gaEvent({
      category: 'homescreen',
      action: 'Смотреть еще',
      strip_name: 'Лучшие каналы',
    });
  }

  gaFeaturedClick(name: string) {
    this.gaEvent({
      category: 'homescreen',
      action: 'Клик по тайтлу в стрипе',
      strip_name: 'Лучшие каналы',
      channel_name: name,
    });
  }

  gaScroll() {
    this.gaEvent({
      category: 'homescreen',
      action: 'Скролл стрипа',
      strip_name: 'Лучшие каналы',
    });
  }

  callAdultOnboardingModal() {
    if (this.isAnonymous) {
      actions.common.showNotificationAuthAndReg(this.$store);
    } else {
      this.showOnboarding = true;
    }
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.home-page {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  padding-top: 4px;
  overflow: hidden;

  .loader {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
  }

  .home-page-inner {
    transition: opacity var(--ease-in) 0.3s;

    @include mobile-and-tablet {
      padding-bottom: 64px;
    }

    &.loading {
      position: absolute;
      z-index: var(--z-rock-bottom);
      width: 100%;
      height: calc(100vh - 64px); // minus the header's height
      padding-bottom: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  .tiles-vod-navigation-group {
    padding-top: 32px;

    @include mobile-and-tablet {
      padding-top: 16px;
    }
  }
}
</style>

<template>
  <CmsLayout class="search-page" data-cy="search-page">
    <main class="container-1440">
      <div class="container-928 with-side-padding">
        <InputSearch
          ref="search"
          v-model="$store.search.query"
          :with-submit-button="true"
          :placeholder="getTranslation('gsearch_input_placeholder')"
          :helper="getTranslation('gsearch_helper')"
          @submitSearch="submitSearch"
        />
      </div>

      <template v-if="queryRouteParam">
        <template v-if="searchQuery && triggerSearch">
          <SearchResultsChannels />

          <SearchResultsVod
            v-if="vodSourcesToShow.length"
            v-for="(source, i) in vodSourcesToShow"
            :key="i + source"
            :source="source"
            :is-loaded="vodSourcesLoaded[source]"
            data-cy="search-results-vod"
          />
        </template>

        <LoaderSpinner v-if="!areAllVodSourcesLoaded || (!searchQuery && !triggerSearch)" />
      </template>

      <template v-else>
        <RecentQueries
          class="mb-minus-12 container-928 with-side-padding"
          :queries="recentQueries"
          @submitSearch="submitSearch"
        />

        <UsefulLinks class="mb-minus-12 with-side-padding" />

        <PopularQueries class="mb-minus-12 with-side-padding" @submitSearch="submitSearch" />

        <TilesVodNavigationGroup
          class="pb-48 pb-tablet-32 pb-mobile-16 with-side-padding"
          data-cy="search"
          @gaTileClick="gaTileClick"
        />

        <VodRecommendations
          v-if="listOfSourceIdsWithoutArchive.length"
          gaEventCategory="global_search"
        />
      </template>

      <ModalSequoia
        v-if="isModalOpen && isModalChannelOpen"
        type="responsive"
        theme-forced="dark"
        :show-title="false"
        :title="getChannelTitle(modalChannel)"
        :with-button-back="isModalChannelOpen && openChannelIdsHistory.length > 1"
        @close="hideModalChannel"
        @back="handleModalChannelStepBack"
      >
        <ChannelDetails v-if="isModalChannelOpen" :channel="modalChannel" />
      </ModalSequoia>
    </main>
  </CmsLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { SequoiaPage } from 'src/mixins';
import { Ref, Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { localStore } from 'src/utils/storage';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import InputSearch from 'src/components/ui/input/InputSearch.vue';
import VodRecommendations from 'src/components/vod/VodRecommendations.vue';
import TilesVodNavigationGroup from 'src/components/ui/tiles/TilesVodNavigationGroup.vue';
import UsefulLinks from 'src/components/search/UsefulLinks.vue';
import PopularQueries from 'src/components/search/PopularQueries.vue';
import RecentQueries from 'src/components/search/RecentQueries.vue';
import SearchResultsChannels from 'src/components/search/SearchResultsChannels.vue';
import SearchResultsVod from 'src/components/search/SearchResultsVod.vue';
import { scrollEverythingToTop } from 'src/utils/scroll-everything-to-top';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import ChannelDetails from 'src/components/tv-channels/ChannelDetails.vue';
import { getChannelTitle } from 'src/utils/channel';
import { makePath } from 'src/utils/url';

@Component({
  components: {
    SearchResultsVod,
    SearchResultsChannels,
    RecentQueries,
    PopularQueries,
    UsefulLinks,
    TilesVodNavigationGroup,
    VodRecommendations,
    InputSearch,
    CmsLayout,
    LoaderSpinner,
    ModalSequoia,
    ChannelDetails,
  },
})
export default class SearchPage extends SequoiaPage {
  getChannelTitle = getChannelTitle;
  triggerSearch = false;
  vodSourcesLoaded: Record<string, boolean> = {};
  recentQueries: string[] = [];
  vodSourcesToShow: string[] = [];
  vodSourcesNotFound: string[] = [];

  @Ref('search')
  readonly refSearch?: Vue;

  @Watch('$store.search.query')
  onSearchQueryChange(val: string) {
    if (val.trim() === '') {
      this.triggerSearch = false;

      if (this.$route.query.q) {
        this.$router.push('/search');
      }
    }
  }

  @Watch('$store.vod.sourcesFe')
  onVodSourcesFeChange() {
    this.submitSearch();
  }

  get searchQuery() {
    return selectors.search.querySelector(this.$store);
  }

  get queryRouteParam() {
    return this.$route.query?.q?.toString() || '';
  }

  get areAllVodSourcesLoaded() {
    return !Object.keys(this.vodSourcesLoaded).some((source) => !this.vodSourcesLoaded[source]);
  }

  getMetaInfo() {
    return {
      title: this.getTranslation('search_meta_title'),
      meta: [
        {
          name: 'description',
          content: this.getTranslation('search_meta_description'),
        },
        {
          name: 'keywords',
          content: this.getTranslation('search_meta_keywords'),
        },
      ],
    };
  }

  get listOfSourceIdsWithoutArchive() {
    return selectors.vod.listOfSourceIdsWithoutArchiveSelector(this.$store);
  }

  get listOfAllSourceIds() {
    return selectors.vod.listOfAllSourceIdsSelector(this.$store);
  }

  get openChannelId() {
    return selectors.tvChannels.openChannelIdSelector(this.$store);
  }

  get modalChannel() {
    return this.getChannelById(this.openChannelId);
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get isModalChannelOpen() {
    return selectors.tvChannels.isModalChannelOpenSelector(this.$store);
  }

  get openChannelIdsHistory() {
    return selectors.tvChannels.openChannelIdsHistorySelector(this.$store);
  }

  get searchUrl() {
    return `/search${this.searchQuery ? '?q=' + this.searchQuery : ''}`;
  }

  async serverPrefetch() {
    await actions.tvChannels.loadChannelsAndGenres(this.$store);
    actions.tvChannels.resetGenre(this.$store);
  }

  async mounted() {
    scrollEverythingToTop();
    (this.refSearch?.$refs?.search as HTMLElement)?.focus();
    this.recentQueries = localStore.get('gsearch_recent_queries') || [];

    if (!this.$store.flags.tvDataState.loaded) {
      await actions.tvChannels.loadChannelsAndGenres(this.$store);
    }

    this.listOfAllSourceIds.forEach((source: string) => {
      Vue.set(this.vodSourcesLoaded, source, false);
    });

    if (this.queryRouteParam) {
      actions.search.setQuery(this.$store, this.queryRouteParam);
      this.submitSearch();
    } else {
      actions.search.setQuery(this.$store, '');
      this.$store.tvChannels.searchQuery = '';
    }

    actions.common.showFooter(this.$store);

    // for navigating via history Back <- button
    window.onpopstate = () => {
      actions.search.setQuery(this.$store, this.queryRouteParam);
      this.$store.tvChannels.searchQuery = this.queryRouteParam;
      if (this.queryRouteParam) {
        this.submitSearch();
      }
    };
  }

  beforeDestroy() {
    actions.search.setQuery(this.$store, '');
    this.$store.tvChannels.searchQuery = '';
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  hideModalChannel() {
    actions.tvChannels.hideChannelDetails(this.$store);
    actions.common.hideModal(this.$store);
    history.pushState({}, '', makePath(this.searchUrl));
  }

  handleModalChannelStepBack() {
    const arrayOfChannelIds = this.openChannelIdsHistory;
    const currentChannelId = arrayOfChannelIds[arrayOfChannelIds.length - 2];

    if (!currentChannelId) {
      actions.tvChannels.setModalChannelId(this.$store, '');
      this.$store.tvChannels.openChannelIdsHistory = [];
      this.$store.tvChannels.isModalChannelOpen = false;
      history.pushState({}, '', makePath(this.searchUrl));
    } else {
      actions.tvChannels.handleModalStepBack(this.$store, currentChannelId);
      history.pushState({}, '', makePath(`/channel/${currentChannelId}`));
      document.querySelector('.modal')?.scrollTo(0, 0);
    }
  }

  saveQueryToLocalStore(query: string) {
    if (query) {
      const index = this.recentQueries.indexOf(query);
      if (index > -1) {
        this.recentQueries.splice(index, 1);
      }

      this.recentQueries.unshift(query);
      if (this.recentQueries.length > 5) {
        this.recentQueries.pop();
      }
      localStore.set('gsearch_recent_queries', this.recentQueries);
      if (this.queryRouteParam !== query) {
        this.$router.push({ path: '/search', query: { q: query } });
      }
    }
  }

  flushTitles() {
    this.listOfAllSourceIds.forEach((source: string) => {
      actions.vod.flushTitles(this.$store, source);
    });
  }

  submitSearch(gaAction = 'Поиск') {
    const searchQueryTrimmed = this.searchQuery.trim();

    if (searchQueryTrimmed === '') {
      return;
    }

    this.vodSourcesToShow.length = 0;
    this.vodSourcesNotFound.length = 0;

    this.listOfAllSourceIds.forEach((source) => {
      Vue.set(this.vodSourcesLoaded, source, false);
    });

    this.saveQueryToLocalStore(searchQueryTrimmed);
    this.$store.tvChannels.searchQuery = searchQueryTrimmed;
    this.triggerSearch = true;

    this.flushTitles();

    this.listOfAllSourceIds.forEach((sourceId) => {
      actions.vod
        .searchTitles(this.$store, {
          limit: 42,
          searchQuery: searchQueryTrimmed,
          sourceId,
        })
        .finally(() => {
          Vue.set(this.vodSourcesLoaded, sourceId, true);

          if (this.$store.vod.sources[sourceId]?.titles?.length === 0) {
            this.vodSourcesNotFound.push(sourceId);
          } else {
            this.vodSourcesToShow.push(sourceId);
          }

          if (this.areAllVodSourcesLoaded) {
            this.vodSourcesToShow = [...this.vodSourcesToShow, ...this.vodSourcesNotFound];
          }
        });
    });

    this.gaEvent({
      category: 'global_search',
      action: gaAction,
      query: searchQueryTrimmed,
    });
  }

  gaTileClick(vod: string | undefined) {
    this.gaEvent({
      category: 'global_search',
      action: 'Клик по тайтлу в стрипе',
      strip_name: 'Кинотеатр',
      vod_name: vod,
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.search-page {
  .container-928 {
    margin-top: 64px;

    @include mobile-and-tablet {
      max-width: 100%;
    }

    @include tablet {
      margin-top: 48px;
    }

    @include mobile {
      margin-top: 32px;
    }
  }

  .input-search-wrap {
    margin-bottom: 24px;

    @include mobile-and-tablet {
      margin-bottom: 32px;
    }
  }

  ::v-deep section {
    @media (min-width: #{$desktop-s-min}) {
      h3 {
        margin-bottom: 24px;
      }
    }
  }
}
</style>

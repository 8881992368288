<template>
  <div class="your-pin">
    <ImageWithHash
      class="background-desktop"
      src="/public/images/backgrounds/candy-gradient.png"
      alt=""
    />
    <div class="with-side-and-bottom-padding content">
      <h3 class="title" v-html="getTranslation('adult_modal_your_pin_title')" />
      <div class="body1">
        <span v-html="getTranslation('adult_modal_your_pin').replace('%pin%', pin)" />
        <span v-text="pin" />
        <a
          class="link nav-colored"
          @click="change"
          v-html="getTranslation('adult_modal_your_pin_here')"
        />
      </div>
      <div class="buttons-wrapper">
        <ButtonDefault @click="signIn">
          <span v-html="getTranslation('signin')" />
        </ButtonDefault>
        <ButtonDefault view="secondary" type="button" @click="$emit('close')">
          <span v-html="getTranslation('cancel')" />
        </ButtonDefault>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import * as api from 'src/api';
import { ADULT_ONBOARDING_STEPS } from 'src/constants';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash, ButtonDefault },
})
export default class YourPin extends SequoiaComponent {
  pin = '';

  get adultProfile() {
    return selectors.adultOnboarding.adultProfileSelector(this.$store);
  }

  async mounted() {
    this.pin = (await api.profile.getProfile(this.adultProfile.id))?.pin;
  }

  async signIn() {
    try {
      this.$emit('set-loader', true);
      await actions.profile.change(this.$store, {
        data: {
          Id: this.adultProfile.id,
          Pin: this.pin,
        },
      });
      this.$emit('set-loader', false);

      this.$emit(
        'goToChannelPageWithAlert',
        this.getTranslation('adult_alert_profile_manage_title'),
        this.getTranslation('adult_alert_profile_manage')
      );
    } catch (err) {}
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_your_pin signin' });
  }

  async change() {
    actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.SetPin);
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_your_pin change_pin' });
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div
    class="banner-hero"
    :class="[$store.theme, { 'with-link': !!link }]"
    :data-cy="dataCy ? `banner-hero-${dataCy}` : ''"
  >
    <a v-if="isExternalLink && link" :href="link" class="inner" target="_blank">
      <BannerHeroImage
        :one-slide="oneSlide"
        :image-desktop="imageDesktop"
        :image-mobile-large="imageMobileLarge"
        :image-mobile-small="imageMobileSmall"
        :preload="preload"
      />
    </a>
    <router-link
      v-else-if="!isExternalLink && link"
      :to="link"
      class="inner"
      @click.native="onInternalLinkClick"
    >
      <BannerHeroImage
        :one-slide="oneSlide"
        :image-desktop="imageDesktop"
        :image-mobile-large="imageMobileLarge"
        :image-mobile-small="imageMobileSmall"
        :preload="preload"
      />
    </router-link>
    <div v-else class="inner">
      <BannerHeroImage
        :one-slide="oneSlide"
        :image-desktop="imageDesktop"
        :image-mobile-large="imageMobileLarge"
        :image-mobile-small="imageMobileSmall"
        :preload="preload"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BannerHeroImage from 'src/components/ui/banners/BannerHeroImage.vue';
import { TBannerHeroImage } from './types';

@Component({
  components: { BannerHeroImage },
})
export default class BannerHero extends Vue {
  @Prop({ required: true })
  imageMobileSmall!: TBannerHeroImage;

  @Prop({ required: true })
  imageMobileLarge!: TBannerHeroImage;

  @Prop({ required: true })
  imageDesktop!: TBannerHeroImage;

  @Prop()
  oneSlide?: boolean;

  @Prop({ default: '' })
  link?: string;

  @Prop({ default: false })
  isExternalLink!: boolean;

  @Prop()
  preload?: boolean;

  @Prop()
  dataCy?: string;

  onInternalLinkClick() {
    // TODO use it once player is integrated to all pages
    // if (this.link?.includes('/channel/')) {
    //   actions.player.setAutoplay(this.$store, true);
    // }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.banner-hero {
  display: block;
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.5s var(--ease-in-out);

  &.with-link {
    @include devices-with-hover {
      &:hover {
        transform: scale(1.02);
      }
    }
  }

  .inner {
    position: relative;
    display: block;
    padding-top: var(--proportion-16-4);

    @include mobile-and-tablet {
      padding-top: var(--proportion-16-9);
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    &.with-link {
      @include devices-with-hover {
        &:hover {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  &.dark {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

    &.with-link {
      @include devices-with-hover {
        &:hover {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>

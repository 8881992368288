<template>
  <div class="start-step">
    <ImageWithHash
      class="background-desktop"
      src="/public/images/backgrounds/banana-gradient.png"
      alt=""
    />
    <div class="with-side-and-bottom-padding content">
      <h3 class="title" v-html="getTranslation('adult_modal_18yo_title')" />
      <div class="body1" v-html="getTranslation('adult_modal_18yo')" />
      <div class="buttons-wrapper">
        <ButtonDefault @click="clickOnYes">
          <span v-html="getTranslation('yes')" />
        </ButtonDefault>
        <ButtonDefault view="secondary" @click="clickOnNo">
          <span v-html="getTranslation('no')" />
        </ButtonDefault>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { actions } from 'src/store/actions';
import logger from 'src/utils/logger';
import { ADULT_ONBOARDING_STEPS } from 'src/constants';
import { Watch } from 'vue-property-decorator';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

const log = logger('adult-onboarding');

@Component({
  components: { ImageWithHash, ButtonDefault },
})
export default class StartStep extends SequoiaComponent {
  redirectToChannelPageAfterLoadingChannels = false;

  @Watch('$store.flags.tvDataState.loaded')
  async onIsProfileLoaded(val: boolean) {
    if (val && this.redirectToChannelPageAfterLoadingChannels) {
      this.redirectToChannelPageAfterLoadingChannels = false;
      await this.$router.push('/channels/now');
    }
  }

  get profiles() {
    return (
      this.$store.account?.profiles?.filter((profile) => {
        return profile.restriction === 'adult';
      }) || []
    );
  }

  async clickOnYes() {
    const profilesLength = this.profiles?.length;

    if (profilesLength === 0) {
      actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.NoProfile);
    } else if (profilesLength === 1) {
      const profile = this.profiles[0];
      if (profile.pin_required) {
        actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.OneProfile);
      } else {
        try {
          await actions.profile.changeProfileWithoutPin(this.$store, profile);

          this.$emit(
            'showNotification',
            this.getTranslation('adult_alert_profile_manage_title'),
            this.getTranslation('adult_alert_profile_manage')
          );

          this.redirectToChannelPageAfterLoadingChannels = true;
        } catch (err) {
          log.error(err);
        }
      }
    } else if (profilesLength > 1) {
      this.$emit(
        'showNotification',
        this.getTranslation('adult_alert_multiple_profiles_title'),
        this.getTranslation('adult_alert_multiple_profiles')
      );
      await this.$router.push('personal/profiles');
    }

    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_18yo yes' });
  }

  clickOnNo() {
    this.$emit('close');
    this.gaEvent({ category: 'adult_profile', action: 'adult_modal_18yo no' });
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="adult-modal">
    <div v-if="isLoading" class="loader">
      <LoaderSpinner />
    </div>
    <component
      :is="currentStep"
      @close="$emit('close')"
      @goToChannelPageWithAlert="goToChannelPageWithAlert"
      @showNotification="showNotification"
      @setLoading="setLoading"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import StartStep from 'src/components/adult-onboarding/steps/StartStep.vue';
import NoProfile from 'src/components/adult-onboarding/steps/NoProfile.vue';
import VerifySession from 'src/components/adult-onboarding/steps/VerifySession.vue';
import SetPin from 'src/components/adult-onboarding/steps/SetPin.vue';
import EnterPin from 'src/components/adult-onboarding/steps/EnterPin.vue';
import YourPin from 'src/components/adult-onboarding/steps/YourPin.vue';
import OneProfile from 'src/components/adult-onboarding/steps/OneProfile.vue';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { ADULT_ONBOARDING_STEPS } from 'src/constants';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';

@Component({
  components: {
    ButtonDefault,
    ProgressBar,
    StartStep,
    NoProfile,
    VerifySession,
    SetPin,
    OneProfile,
    EnterPin,
    YourPin,
    LoaderSpinner,
  },
})
export default class AdultModal extends SequoiaComponent {
  isLoading = false;

  async mounted() {
    await actions.adultOnboarding.setCurrentStep(this.$store, ADULT_ONBOARDING_STEPS.StartStep);
  }

  async beforeDestroy() {
    await actions.adultOnboarding.setCurrentStep(this.$store, '');
  }

  get currentStep() {
    return selectors.adultOnboarding.currentStepSelector(this.$store);
  }

  async showNotification(title: string, text: string, withButton = false) {
    await actions.adultOnboarding.setNotificationTitle(this.$store, title);
    await actions.adultOnboarding.setNotificationMessage(this.$store, text);
    await actions.adultOnboarding.setWithButton(this.$store, withButton);
    await actions.adultOnboarding.setShowNotification(this.$store, true);
  }

  async goToChannelPageWithAlert(title: string, text: string) {
    await this.showNotification(title, text, true);
    await this.$router.push('/channels/now');
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.adult-modal {
  min-height: 280px;

  &::v-deep {
    .adult-title {
      @extend %heading3;
    }

    .background-desktop {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: var(--z-rock-bottom);

      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }

    .content {
      @include desktop {
        max-width: 400px;
      }
    }
  }

  .loader {
    position: absolute;
    top: 0;
    z-index: var(--z-8);
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--alpha-light-7);
  }
}
</style>

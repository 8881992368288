<template>
  <div v-if="!shouldHideStrip" class="overflow-hidden">
    <SectionHeader
      class="with-side-padding"
      :icon="IconHeart"
      :text="getTranslation('foryou_channels')"
    />
    <ChannelsStrip
      :channels-quantity="12"
      :hide-open-channel="false"
      :channels="suggestedChannels"
      data-cy="suggested-channels"
      @gaFavorite="gaFavorite"
      @gaClick="gaClick"
      @gaScroll="gaScroll"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import ChannelsStrip from 'src/components/home/ChannelsStrip.vue';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import { TChannelEnhanced } from 'src/api/channels/types';
import { actions } from 'src/store/actions';
import IconHeart from 'src/svg/heart.svg';

const TILES_COUNT = 12;

@Component({
  components: { ChannelsStrip, SectionHeader },
})
export default class RecentChannels extends SequoiaComponent {
  IconHeart = IconHeart;
  get shouldHideStrip() {
    return this.channelsRecentAndFavLoaded && !this.suggestedChannels?.length;
  }

  get channelsRecentAndFavLoaded() {
    return selectors.tvChannels.channelsRecentAndFavLoadedSelector(this.$store);
  }

  get favoriteChannels() {
    return this.prepareChannels(selectors.tvChannels.favoritesArraySelector(this.$store));
  }

  get recommendedChannels() {
    return this.prepareChannels(selectors.tvChannels.recommendedArraySelector(this.$store));
  }

  get channels() {
    return selectors.tvChannels.allChannelsSelector(this.$store);
  }

  get suggestedChannels() {
    let suggestedChannels = this.favoriteChannels.slice(0, TILES_COUNT) || [];
    const favoriteChannelsCount = suggestedChannels.length;

    if (favoriteChannelsCount < TILES_COUNT) {
      const suggestedChannelsIds = suggestedChannels.map((channel: TChannelEnhanced) => channel.id);
      const recommendedChannels =
        this.recommendedChannels
          ?.filter((channel) => !suggestedChannelsIds.includes(channel.id))
          .slice(0, TILES_COUNT - favoriteChannelsCount) || [];
      suggestedChannels = [...suggestedChannels, ...recommendedChannels];
    }
    return suggestedChannels;
  }

  prepareChannels(channels: Array<TChannelEnhanced>) {
    return channels.filter((channel) => this.channels[channel.id]);
  }

  gaFavorite() {
    actions.metrics.setGaParams(this.$store, {
      category: 'homescreen',
      control_type: 'mouse',
      strip_name: 'Каналы для вас',
    });
  }

  gaClick(id: string | undefined) {
    this.gaEvent({
      category: 'homescreen',
      action: 'Клик по тайтлу в стрипе',
      strip_name: 'Каналы для вас',
      channel_name: id,
    });
  }

  gaScroll() {
    this.gaEvent({
      category: 'homescreen',
      action: ' Скролл стрипа',
      strip_name: 'Каналы для вас',
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

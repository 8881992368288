<template>
  <div v-if="!isAnonymous && !shouldHideStrip" class="overflow-hidden">
    <SectionHeader
      class="with-side-padding"
      :icon="IconTvHistory"
      :text="getTranslation('recent_channels')"
    />
    <ChannelsStrip
      :channels-quantity="6"
      :hide-open-channel="false"
      :channels="recentlyWatchedChannels"
      data-cy="recent-channels"
      :open-watch="true"
      @gaFavorite="gaFavorite"
      @gaClick="gaClick"
      @gaScroll="gaScroll"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import ChannelsStrip from 'src/components/home/ChannelsStrip.vue';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import { actions } from 'src/store/actions';
import IconTvHistory from 'src/svg/tv-history.svg';

@Component({
  components: { ChannelsStrip, SectionHeader },
})
export default class RecentChannels extends SequoiaComponent {
  IconTvHistory = IconTvHistory;
  get recentlyWatchedChannels() {
    return selectors.tvChannels.recentlyWatchedArraySelector(this.$store).slice(0, 6);
  }

  get shouldHideStrip() {
    return this.channelsRecentAndFavLoaded && !this.recentlyWatchedChannels?.length;
  }

  get channelsRecentAndFavLoaded() {
    return selectors.tvChannels.channelsRecentAndFavLoadedSelector(this.$store);
  }

  gaFavorite() {
    actions.metrics.setGaParams(this.$store, {
      category: 'homescreen',
      control_type: 'mouse',
      strip_name: 'Последние каналы',
    });
  }

  gaClick(id: string | undefined) {
    this.gaEvent({
      category: 'homescreen',
      action: 'Клик по тайтлу в стрипе',
      channel_name: id,
      strip_name: 'Последние каналы',
    });
  }

  gaScroll() {
    this.gaEvent({
      category: 'homescreen',
      action: 'Скролл стрипа',
      strip_name: 'Последние каналы',
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

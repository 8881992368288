<template>
  <section
    v-if="links && links.length"
    class="useful-links pb-48 pb-tablet-32 pb-mobile-32"
    data-cy="useful-links"
  >
    <h3 v-html="getTranslation('gsearch_useful_links')" />
    <div class="links flex">
      <component
        v-for="(link, i) in links"
        :is="isUrlExternal(link.link) ? 'a' : 'router-link'"
        :key="link._id"
        :to="link.link"
        :href="link.link"
        class="link nav-colored"
        :target="isUrlExternal(link.link) ? '_blank' : ''"
        :data-cy="`useful-link-${i}`"
        @click.native="onClick(link.titleId)"
        v-html="getTranslation(link.titleId)"
      />
    </div>
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { isUrlExternal } from 'src/utils/url';

@Component
export default class UsefulLinks extends SequoiaComponent {
  isUrlExternal = isUrlExternal;

  get links() {
    return selectors.siteConfig.globalSearchUsefulLinksSelector(this.$store);
  }

  onClick(title: string) {
    this.gaEvent({
      category: 'global_search',
      action: 'Клик - полезная ссылка',
      link_text: this.getTranslation(title),
    });
  }
}
</script>

<style lang="scss" scoped>
.links {
  .link {
    margin-right: 32px;
    margin-bottom: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

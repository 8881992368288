<template>
  <div v-if="loading || history.length" class="home-history overflow-hidden">
    <SectionHeader
      class="with-side-padding"
      :text="getTranslation('history')"
      :icon="IconRecentlyViewed"
    />

    <div v-if="loading" class="row nowrap with-side-padding">
      <TileThumbnail
        v-for="one in 6"
        :key="one"
        class="tile col-desktop-2 col-tablet-2 col-mobile-2"
        image=""
        title=""
      />
    </div>

    <div v-else>
      <HomeContentSlider
        type="adaptive"
        :theme-forced="theme"
        class="tile-posters"
        @gaScroll="gaScroll"
      >
        <HomeHistoryTile v-for="thumb in history" :key="thumb.id" :thumb="thumb" />
      </HomeContentSlider>
    </div>
  </div>
</template>

<script lang="ts">
import { HOME_SCREEN_TILES_COUNT } from 'src/constants';
import * as api from 'src/api';
import Component from 'vue-class-component';
import { convertToMinutes, convertToMilliseconds } from 'src/utils/time/convert-time';
import IconSVG from 'src/components/IconSVG.vue';
import HomeContentSlider from 'src/components/home/HomeContentSlider.vue';
import TileThumbnail from 'src/components/ui/tiles/TileThumbnail.vue';
import IconRecentlyViewed from 'src/svg/genres/recently-viewed.svg';
import { calculateSizeByAspectRatio } from 'src/utils/images';
import { SequoiaComponent } from 'src/mixins';
import HomeHistoryTile from 'src/components/home/HomeHistoryTile.vue';
import { Prop, Watch } from 'vue-property-decorator';
import logger from 'src/utils/logger';
import { selectors } from 'src/store/selectors';
import { VODTitlePreview } from 'src/models/ts/vod/v2/vod';
import { TVODTitleEnhanced } from 'src/api/vod/types';
import { VODPause } from 'src/models/ts/pauses/v1/pauses';
import { THomeHistoryTile } from 'src/store/home/types';
import SectionHeader from 'src/components/ui/SectionHeader.vue';

const log = logger('home');

interface TPauseHistory extends VODPause {
  duration?: string;
  timelinePosition?: number;
  title: TVODTitleEnhanced | null;
  episode: VODTitlePreview | null;
}

@Component({
  components: {
    SectionHeader,
    IconSVG,
    TileThumbnail,
    HomeContentSlider,
    HomeHistoryTile,
  },
})
export default class HomeHistory extends SequoiaComponent {
  IconRecentlyViewed = IconRecentlyViewed;

  loading = true;
  history: THomeHistoryTile[] = [];

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get serverTimeMs() {
    return selectors.appInfo.serverTimeMsSelector(this.$store);
  }

  async mounted() {
    this.history = await this.fetchHistory();
  }

  async fetchHistory(): Promise<THomeHistoryTile[]> {
    try {
      const pausesRaw =
        (await api.pauses.getVodPauses({ params: { limit: HOME_SCREEN_TILES_COUNT } }).catch())
          ?.pauses || [];

      const pausesRawFiltered = pausesRaw.filter((pause) =>
        selectors.vod.listOfAllSourceIdsSelector(this.$store).includes(pause.sourceId)
      );

      const pauses = await Promise.all(
        pausesRawFiltered.map(async (pause) => {
          const titleResponse = await api.vod.getTitle(pause.sourceId, pause.titleId).catch(() => {
            // do nothing
          });

          const title: TVODTitleEnhanced = {
            preview: undefined,
            details: undefined,
            ...titleResponse,
          };

          return {
            ...pause,
            duration: `${Math.round(convertToMinutes(pause.totalDurationMsec, 'millisecond'))} мин`,
            timelinePosition: pause.msecFromStart / pause.totalDurationMsec,
            title,
          };
        })
      );

      const episodes = await Promise.all(
        pauses.map((pause) =>
          pause.seasonId
            ? api.vod.getSeasonEpisodes(pause.sourceId, pause.titleId, pause.seasonId).catch(() => {
                // do nothing
                return null;
              })
            : new Promise<null>((resolve) => resolve(null))
        )
      );

      const pausesFiltered = pauses.filter(
        ({ sourceId, mediaItemExpiresAt, title, episodeId }, index) => {
          const hasTitlePreview = !!title.preview;
          const isNotArchive = sourceId !== 'archive';
          const mediaItemExpiresAtMs = convertToMilliseconds(mediaItemExpiresAt, 'second');
          const episode = episodes?.[index]?.episodes?.find((e) => e.id === episodeId) || null;
          const hasSeasonsAndEmptyEpisodes = title?.details?.seasons && !episode;
          return (
            hasTitlePreview &&
            !hasSeasonsAndEmptyEpisodes &&
            (isNotArchive || (mediaItemExpiresAt > 0 && mediaItemExpiresAtMs > this.serverTimeMs))
          );
        }
      );

      return pausesFiltered.map((pause, index) => {
        const episode = episodes?.[index]?.episodes?.find((e) => e.id === pause.episodeId) || null;
        return this.preparePoster({
          ...pause,
          episode,
          id: pause.title?.preview?.id || index.toString(),
        });
      });
    } catch (err) {
      log.error(err);
      return [];
    } finally {
      this.loading = false;
    }
  }

  preparePoster(pause: TPauseHistory) {
    const image = this.preparePosterImage(pause);
    const description = this.preparePosterDescription(pause);

    let link = '';

    if (pause.sourceId === 'archive') {
      link = `/archive/${pause.id}/${pause.title?.preview?.hasSeries ? pause.episodeId + '/' : ''}${
        pause.mediaItemId
      }/watch`;
    } else {
      link = `/vod/${pause.sourceId}/${pause.title?.preview?.id}/${
        pause.title?.preview?.hasSeries ? pause.episodeId + '/watch' : 'watch'
      }`;
    }

    return {
      id: pause.id,
      title: pause.title?.preview?.title || '',
      description,
      link,
      image,
      duration: pause.duration || '',
      timelinePosition: pause.timelinePosition || 0,
    };
  }

  preparePosterImage(pause: TPauseHistory) {
    let image = null;
    let src = '';
    const maxWidth = 180; // the largest width for all dimensions (on mobile L)
    const maxHeight = 132; // the largest height for all dimensions (on mobile L)
    const portraitImageAspectRatio = 7 / 10;
    let width = 0;
    let height = 0;
    let type = '';
    let orientation: 'portrait' | 'landscape' = 'landscape';

    if (!pause.title?.preview) {
      return { src, width, height, type, orientation };
    }

    const { posters, thumbnails, additionalImages } = pause.title?.preview;
    switch (pause.sourceId) {
      case 'amedia':
      case 'megogo':
        image = additionalImages?.find((image) => image.originalType === 'image_470х270');
        if (!image) {
          image = thumbnails?.find((image) => image.originalType === 'small');
        }
        orientation = 'portrait';
        break;
      case 'start':
        image = thumbnails && thumbnails.length ? thumbnails[0] : null;
        orientation = 'portrait';
        break;
      case 'archive':
        image = posters && posters.length ? posters[0] : null;
        if (image?.aspectRatio) {
          const aspectRatio = image.aspectRatio.split(':').map((one) => parseInt(one, 10));
          const imageSize = calculateSizeByAspectRatio(
            maxWidth,
            maxHeight,
            aspectRatio[0],
            aspectRatio[1]
          );
          width = imageSize.width;
          height = imageSize.height;
        }
        break;
    }

    if (!image) {
      image = posters ? posters[0] : null;
      orientation = 'portrait';
    }

    if (!image && thumbnails) {
      image = thumbnails[0];
    }

    if (image) {
      src = image.path;
      type = image.type;
      if (!width && !height) {
        if (orientation === 'portrait') {
          width = Math.round(maxHeight * portraitImageAspectRatio);
          height = maxHeight;
        } else {
          width = maxWidth;
          height = maxHeight;
        }
      }
    }

    return { src, width, height, type, orientation };
  }

  preparePosterDescription(pause: TPauseHistory) {
    let description = '';
    description += pause.episode?.title || '';
    return description;
  }

  gaScroll() {
    this.gaEvent({
      category: 'homescreen',
      action: 'Скролл стрипа',
      strip_name: 'История просмотра',
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

<style lang="scss" scoped>
.home-history {
  .nowrap {
    flex-wrap: nowrap;
    overflow: hidden;
  }
}
</style>

<template>
  <div v-if="images && images.length === 20">
    <h3 class="h3 with-side-padding" v-html="getTranslation('best_channels')" />
    <div class="featured-channels" data-cy="featured-channels" :class="theme">
      <!-- Custom grid for desktop -->
      <div class="hidden-mobile-and-tablet grid with-side-padding">
        <button
          v-for="(img, i) in imagesDesktop"
          :key="img.id"
          type="button"
          class="channel"
          :class="{ large: getGridVariation(i) }"
          @click="selectChannel(img.id, img.name)"
        >
          <LazyImage
            class="channel-image"
            :with-icon="true"
            :src="img.src"
            :width="img.width"
            :height="img.height"
          />
        </button>
        <TileViewMore
          link="/channel"
          :text="getTranslation('all_channels')"
          @click="$emit('gaViewMore')"
        />
      </div>

      <!-- Mobile & tablet -->
      <div class="visible-mobile-and-tablet">
        <ContentSlider type="adaptive" :theme-forced="theme">
          <TilePosterChannel
            v-for="(img, index) in imagesMobile"
            :key="img.id + index.toString()"
            class="tile col-desktop-2 col-tablet-2 col-mobile-2"
            :channel-id="img.id"
            :channel-image="{
              src: img.src,
              width: img.width,
              height: img.height,
            }"
            :show-favorites="false"
            :is-in-favorites="false"
            @toggleFavorite="selectChannel(img.id, img.name)"
          />
        </ContentSlider>
      </div>

      <div class="with-side-padding">
        <ButtonViewMore
          :full-width="true"
          link="/channel"
          :text="getTranslation('all_channels')"
          @click="$emit('gaViewMore')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import ButtonViewMore from 'src/components/ui/buttons/ButtonViewMore.vue';
import TileViewMore from 'src/components/ui/tiles/TileViewMore.vue';
import HomeContentSlider from 'src/components/home/HomeContentSlider.vue';
import LazyImage from 'src/components/LazyImage.vue';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import TilePosterChannel from 'src/components/ui/tiles/TilePosterChannel.vue';
import { getChannelLogo } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';

@Component({
  components: {
    TilePosterChannel,
    ContentSlider,
    LazyImage,
    ButtonViewMore,
    TileViewMore,
    HomeContentSlider,
  },
})
export default class FeaturedChannels extends SequoiaComponent {
  generatedVariationNumber = Math.floor(Math.random() * 85);

  get channels() {
    return selectors.tvChannels.allChannelsSelector(this.$store);
  }

  // TODO with & without serverPrefetch
  get filteredChannels() {
    return Object.values(this.channels)
      .filter((channel) => {
        if (channel?.keyNumber) {
          // TV API 2.0
          return channel?.relevantGenres?.find(
            (genre) =>
              this.$store.tvChannels.genres.find((g) => g?.id === genre?.genreId)?.title ===
              'Featured'
          );
        } else {
          // TV API 1.0
          return channel.info?.metaInfo?.genres?.includes('Featured');
        }
      })
      .slice(0, 20);
  }

  get images() {
    return this.filteredChannels.map((channel, i) => {
      return {
        id: channel?.id || `channel${i}`,
        src: this.getChannelLogo(channel),
        name: channel?.name || '',
      };
    });
  }

  get imagesDesktop() {
    return this.images.map((image) => ({ ...image, width: 396, height: 228 }));
  }

  get imagesMobile() {
    return this.images.map((image) => ({ ...image, width: 205, height: 115 }));
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getGridVariation(i: number) {
    // 85 variations
    const gridVariations = [
      i === 0 || i === 2 || i === 3 || i === 5,
      i === 0 || i === 2 || i === 4 || i === 5,
      i === 0 || i === 3 || i === 4 || i === 5,
      i === 0 || i === 1 || i === 6 || i === 8,
      i === 0 || i === 1 || i === 4 || i === 9,
      i === 0 || i === 2 || i === 3 || i === 9,
      i === 0 || i === 4 || i === 8 || i === 10,
      i === 0 || i === 6 || i === 9 || i === 10,
      i === 0 || i === 2 || i === 9 || i === 11,
      i === 0 || i === 11 || i === 12 || i === 13,

      i === 1 || i === 2 || i === 3 || i === 4,
      i === 1 || i === 2 || i === 3 || i === 5,
      i === 1 || i === 3 || i === 4 || i === 5,
      i === 1 || i === 2 || i === 6 || i === 8,
      i === 1 || i === 4 || i === 6 || i === 8,
      i === 1 || i === 2 || i === 6 || i === 9,
      i === 1 || i === 3 || i === 6 || i === 9,
      i === 1 || i === 4 || i === 9 || i === 10,
      i === 1 || i === 5 || i === 9 || i === 11,
      i === 1 || i === 11 || i === 12 || i === 13,

      i === 2 || i === 3 || i === 4 || i === 5,
      i === 2 || i === 3 || i === 4 || i === 7,
      i === 2 || i === 3 || i === 5 || i === 7,
      i === 2 || i === 3 || i === 6 || i === 9,
      i === 2 || i === 5 || i === 10 || i === 11,
      i === 2 || i === 7 || i === 10 || i === 11,
      i === 2 || i === 7 || i === 8 || i === 11,
      i === 2 || i === 9 || i === 11 || i === 13,
      i === 2 || i === 9 || i === 12 || i === 13,
      i === 2 || i === 11 || i === 12 || i === 13,

      i === 3 || i === 4 || i === 5 || i === 7,
      i === 3 || i === 4 || i === 6 || i === 8,
      i === 3 || i === 4 || i === 5 || i === 8,
      i === 3 || i === 4 || i === 9 || i === 10,
      i === 3 || i === 9 || i === 11 || i === 12,
      i === 3 || i === 10 || i === 11 || i === 12,
      i === 3 || i === 9 || i === 11 || i === 13,
      i === 3 || i === 9 || i === 12 || i === 13,
      i === 3 || i === 10 || i === 12 || i === 13,
      i === 3 || i === 10 || i === 11 || i === 13,

      i === 4 || i === 5 || i === 6 || i === 7,
      i === 4 || i === 5 || i === 6 || i === 8,
      i === 4 || i === 5 || i === 6 || i === 9,
      i === 4 || i === 5 || i === 8 || i === 9,
      i === 4 || i === 5 || i === 8 || i === 11,
      i === 4 || i === 5 || i === 9 || i === 11,
      i === 4 || i === 5 || i === 10 || i === 11,
      i === 4 || i === 9 || i === 12 || i === 13,
      i === 4 || i === 10 || i === 12 || i === 13,
      i === 4 || i === 11 || i === 12 || i === 13,

      i === 5 || i === 6 || i === 8 || i === 9,
      i === 5 || i === 7 || i === 8 || i === 9,
      i === 5 || i === 6 || i === 9 || i === 10,
      i === 5 || i === 6 || i === 8 || i === 10,
      i === 5 || i === 6 || i === 9 || i === 10,
      i === 5 || i === 7 || i === 8 || i === 10,
      i === 5 || i === 7 || i === 9 || i === 10,

      i === 6 || i === 7 || i === 8 || i === 9,
      i === 6 || i === 7 || i === 8 || i === 10,
      i === 6 || i === 7 || i === 8 || i === 11,
      i === 6 || i === 7 || i === 9 || i === 10,
      i === 6 || i === 7 || i === 8 || i === 11,
      i === 6 || i === 7 || i === 9 || i === 11,
      i === 6 || i === 7 || i === 10 || i === 11,

      i === 7 || i === 9 || i === 10 || i === 11,
      i === 7 || i === 9 || i === 10 || i === 12,
      i === 7 || i === 9 || i === 11 || i === 12,
      i === 7 || i === 10 || i === 11 || i === 12,

      i === 8 || i === 9 || i === 10 || i === 11,
      i === 8 || i === 9 || i === 10 || i === 12,
      i === 8 || i === 9 || i === 11 || i === 12,
      i === 8 || i === 10 || i === 11 || i === 12,
      i === 8 || i === 9 || i === 10 || i === 13,
      i === 8 || i === 10 || i === 11 || i === 13,
      i === 8 || i === 11 || i === 12 || i === 13,

      i === 10 || i === 11 || i === 12 || i === 13,
      i === 10 || i === 11 || i === 12 || i === 15,
      i === 10 || i === 11 || i === 13 || i === 15,
      i === 10 || i === 11 || i === 14 || i === 15,
      i === 10 || i === 12 || i === 14 || i === 15,

      i === 11 || i === 12 || i === 13 || i === 14,
      i === 11 || i === 12 || i === 13 || i === 15,
      i === 11 || i === 12 || i === 14 || i === 15,
      i === 11 || i === 13 || i === 14 || i === 15,

      i === 12 || i === 13 || i === 14 || i === 15,
    ];

    return gridVariations[this.generatedVariationNumber];
  }

  async selectChannel(id: string, name: string) {
    this.$emit('gaFeaturedClick', name);
    this.$set(this.$store, 'channelWithExternalLaunchRequested', id);
    await this.$router.push({ path: `/channel/${id}/about` }).catch(() => {
      // do nothing
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/shadows';

.featured-channels {
  .channel {
    overflow: hidden;
    border-radius: 4px;
    transition: all var(--ease-out) 0.15s;
    backface-visibility: hidden;
    transform: perspective(1000px);

    @media (min-width: #{$desktop-m-min}) {
      &:nth-child(n + 19) {
        display: none;
      }

      &.large {
        grid-column: span 2;
        grid-row: span 2;
      }
    }

    @media (max-width: #{$desktop-s-max}) {
      grid-column: span 2;
    }

    @include desktop-s {
      &:nth-child(n + 11) {
        display: none;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      padding-top: var(--proportion-16-9);
    }

    &:hover,
    &:active {
      transform: scale(0.96);
    }

    @include devices-with-hover {
      &:hover {
        transform: scale(1.04);
      }

      &:active {
        transform: scale(1.02);
      }
    }
  }

  .channel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
  }

  .tile-view-more {
    grid-column: 11 / span 2;
    grid-row: 1 / span 3;
    width: 100%;
    margin: 0;

    @include desktop-s {
      grid-row: 1 / span 2;
    }

    @include mobile-and-tablet {
      display: none;
    }
  }

  // Content Slider
  $content-slider-shadow-space: 20px; // additional space to compensate shadow
  .content-slider {
    padding-bottom: $content-slider-shadow-space;
    margin-bottom: 16px - $content-slider-shadow-space;
  }

  .slides {
    flex-wrap: nowrap;
    padding-top: 20px;
    padding-bottom: 100px;
    margin-top: -20px;
    margin-bottom: -100px;
    overflow-y: auto;
  }

  .slide {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    button {
      margin-bottom: 16px;
    }
  }

  .button.view-more {
    display: none;

    @include mobile-and-tablet {
      display: flex;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .channel {
      @extend %shadow-default-light;
    }
  }

  &.dark {
    .channel {
      @extend %shadow-default-dark;
    }
  }
}
</style>

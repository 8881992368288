<template>
  <div class="tile-collection" :class="theme" @click="$emit('click')">
    <BackgroundTiles
      size="small"
      :width-proportion="widthProportion"
      :height-proportion="heightProportion"
      :col-num="4"
      :row-num="3"
      :posters="posters"
      :poster="poster"
    />
    <h4 v-html="title" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import BackgroundTiles from 'src/components/ui/BackgroundTiles.vue';

@Component({
  components: { BackgroundTiles },
})
export default class TileCollection extends SequoiaComponent {
  @Prop({ required: true })
  widthProportion!: number;

  @Prop({ required: true })
  heightProportion!: number;

  @Prop({ required: true })
  title!: string;

  @Prop()
  posters?: string[];

  @Prop()
  poster?: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/overlays';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/shadows';

// stylelint-disable property-no-vendor-prefix

.tile-collection {
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 200px;
  overflow: hidden;
  color: var(--c-dark-font-primary);
  cursor: pointer;
  border-radius: 8px;
  transition: all var(--ease-out) 0.15s;
  transform: perspective(1000px);
  -webkit-appearance: initial;
  backface-visibility: hidden;

  @include desktop-s {
    height: 144px;
  }

  @include mobile-and-tablet {
    height: 128px;
  }

  @include devices-with-hover {
    &:hover {
      transform: scale(1.08);
    }

    &:active {
      transform: scale(1.04);
    }
  }

  &:focus {
    transform: scale(0.96);
  }

  &.overlay-default-before {
    @include overlay-color-before(var(--c-overlay-lvl-40));
  }

  h4 {
    position: relative;
    z-index: var(--z-4);
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    margin-bottom: 0;
    text-align: center;

    @media (max-width: #{$desktop-m-min}) {
      @include heading5;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    @extend %shadow-default-light;
  }

  &.dark {
    @extend %shadow-default-dark;
  }
}
</style>

<template>
  <div v-if="!isBannersDataLoaded || bannersPrepared.length" class="home-banners">
    <!-- One banner (server side) -->
    <div class="single-banner container-1440 with-side-padding">
      <section class="pb-48 pb-tablet-32 pb-mobile-32">
        <BannerHero
          v-if="bannersPrepared.length"
          class="home-banner"
          :style="{ visibility: sliderInitialized ? 'hidden' : 'visible' }"
          :image-mobile-small="bannersPrepared[0].imageMobileSmall"
          :image-mobile-large="bannersPrepared[0].imageMobileLarge"
          :image-desktop="bannersPrepared[0].imageDesktop"
          :link="extractLink(bannersPrepared[0])"
          :is-external-link="isExternalLink(bannersPrepared[0])"
          :preload="true"
          :one-slide="true"
          data-cy="home"
          @click.native="onClickBanner(bannersPrepared[0])"
        />
        <BannerHero
          v-else-if="!bannersPrepared.length && !isBannersDataLoaded"
          class="home-banner"
          :image-mobile-small="{ width: 0, height: 0, src: BLANK_IMAGE }"
          :image-mobile-large="{ width: 0, height: 0, src: BLANK_IMAGE }"
          :image-desktop="{ width: 0, height: 0, src: BLANK_IMAGE }"
          :one-slide="true"
        />
      </section>
    </div>

    <!-- Banners carousel (client side) -->
    <HomeContentSlider
      v-if="isMounted && bannersPrepared.length > 1"
      type="carousel"
      class="slider"
      :theme-forced="theme"
      :style="{ visibility: sliderInitialized ? 'visible' : 'hidden' }"
      :auto-rotation="10000"
      @ready="sliderInitialized = true"
      @gaScroll="gaScroll"
    >
      <BannerHero
        v-for="(banner, i) in bannersPrepared"
        :key="banner.id"
        class="home-banner"
        :image-mobile-small="banner.imageMobileSmall"
        :image-mobile-large="banner.imageMobileLarge"
        :image-desktop="banner.imageDesktop"
        :link="extractLink(banner)"
        :is-external-link="isExternalLink(banner)"
        :preload="i === 0"
        data-cy="home"
        @click.native="onClickBanner(banner)"
      />
    </HomeContentSlider>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import sortBy from 'lodash/sortBy';
import HomeContentSlider from 'src/components/home/HomeContentSlider.vue';
import BannerHero from 'src/components/ui/banners/BannerHero.vue';
import { selectors } from 'src/store/selectors';
import { useHttpsProtocol } from 'src/utils/url';
import { TBanner } from 'src/api/home/types';
import { Prop, Watch } from 'vue-property-decorator';
import { BLANK_IMAGE } from 'src/constants';
import { isBannersDataLoadedSelector } from 'src/store/home/selectors';

@Component({
  components: { HomeContentSlider, BannerHero },
})
export default class HomeBanners extends SequoiaComponent {
  bannersPrepared: any[] = [];
  isMounted = false;
  sliderInitialized = false;
  BLANK_IMAGE = BLANK_IMAGE;

  @Watch('banners')
  onBannersChange() {
    this.bannersPrepared = this.bannersMapped;
  }

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get banners() {
    return selectors.home.bannersSelector(this.$store);
  }

  get isBannersDataLoaded() {
    return isBannersDataLoadedSelector(this.$store);
  }

  get bannersMapped() {
    const width16x4 = 1312; // max desktop width
    const width16x9l = 768; // max large-mobile width
    const width16x9s = 480; // max small-mobile width

    const height16x4 = Math.round((width16x4 * 4) / 16); // desktop
    const height16x9l = Math.round((width16x9l * 9) / 16); // mobile-l
    const height16x9s = Math.round((width16x9s * 9) / 16); // mobile-s
    const banners = this.banners || [];

    return banners
      .filter((banner) => banner.plainImage16x4 && banner.plainImage16x9)
      .map((banner) => {
        return {
          ...banner,
          imageDesktop: {
            src: useHttpsProtocol(banner.plainImage16x4),
            width: width16x4,
            height: height16x4,
          },
          imageMobileLarge: {
            src: useHttpsProtocol(banner.plainImage16x9),
            width: width16x9l,
            height: height16x9l,
          },
          imageMobileSmall: {
            src: useHttpsProtocol(banner.plainImage16x9),
            width: width16x9s,
            height: height16x9s,
          },
        };
      });
  }

  created() {
    this.bannersPrepared = this.bannersMapped?.length ? [this.bannersMapped?.[0]] : [];
  }

  mounted() {
    this.bannersPrepared = this.bannersMapped;
    this.isMounted = true;
  }

  isExternalLink(banner: TBanner) {
    const action = this.getFirstAction(banner);
    if (action?.method === 'adult-onboarding') {
      return false;
    }
    return action?.method === 'external-url';
  }

  extractLink(banner: TBanner) {
    const action = this.getFirstAction(banner);

    if (action?.method === 'adult-onboarding') {
      return '/adult';
    }

    if (action?.method === 'external-url') {
      return banner.externalUrl;
    }

    if (action?.method === 'internal-ref') {
      return banner.internalUrl;
    }
    return '';
  }

  getFirstAction(banner: TBanner) {
    if (!banner.actionMethods) {
      return null;
    }
    return sortBy(banner.actionMethods, 'priority')[0];
  }

  gaScroll(i: number) {
    this.gaEvent({
      category: 'homescreen',
      action: 'Скролл карусели баннеров',
      banner_id: this.bannersMapped?.[i]?.id,
    });
  }

  gaClick(bannerId: string) {
    this.gaEvent({
      category: 'homescreen',
      action: 'Клик по баннеру в карусели',
      banner_id: bannerId,
    });
  }

  onClickBanner(banner: TBanner) {
    const action = this.getFirstAction(banner);
    if (action?.method === 'adult-onboarding') {
      this.$emit('call-adult-onboarding-modal');
    }

    this.gaClick(banner.id);
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

<style lang="scss" scoped>
@import 'src/styles/common/dimensions-variables';
@import 'src/styles/common/paddings-variables';
@import 'src/styles/placeholders-and-mixins/media-queries';

$gap-between-banners: 10px;

.home-banners {
  position: relative;

  .single-banner {
    margin-bottom: -48px;

    .home-banner {
      margin: 0 auto;
    }
  }

  .home-banner {
    width: 100%;
    max-width: $content-max-width;
    margin: 0 $gap-between-banners;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::v-deep .content-slider .slider-items {
    padding-right: $side-padding-desktop + $gap-between-banners;
    padding-left: $side-padding-desktop + $gap-between-banners;

    @include tablet {
      padding-right: $side-padding-tablet + $gap-between-banners;
      padding-left: $side-padding-tablet + $gap-between-banners;
    }

    @include mobile {
      padding-right: $side-padding-mobile;
      padding-left: $side-padding-mobile;
    }
  }
}
</style>

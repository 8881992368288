<template>
  <section
    class="search-results-channels"
    :class="hasChannels ? 'pb-64 pb-tablet-48 pb-mobile-48' : 'pb-48 pb-tablet-32 pb-mobile-32'"
    data-cy="search-results-channels"
  >
    <h3 class="with-side-padding" v-html="getTranslation('channels')" />

    <div v-if="!hasChannels" class="with-side-padding">
      <p v-html="getTranslation('channels_were_not_found')" />
    </div>

    <ContentSlider
      v-else
      type="adaptive"
      :theme-forced="theme"
      :same-width-slides="true"
      @gaScroll="
        gaEvent({
          category: 'global_search',
          action: 'Скролл стрипа',
          strip_name: getTranslation('channels'),
        })
      "
    >
      <TilePosterChannel
        v-for="(channel, i) in channelsMapped"
        :key="channel.id + i.toString()"
        class="col-desktop-2 col-tablet-2 col-mobile-2"
        :channel-id="channel.id"
        :channel-name="channel.name"
        :channel-image="channel.image"
        :show-favorites="!isAnonymous"
        :is-in-favorites="channel.inFavorites"
        :program-title="channel.currentProgram ? channel.currentProgram.title : ''"
        :program-start-h-m="channel.currentProgram ? channel.currentProgram.startTimeHM : ''"
        :program-end-h-m="channel.currentProgram ? channel.currentProgram.endTimeHM : ''"
        data-cy="search"
        @toggleFavorite="toggleChannelFavorite(channel.id)"
        @click="
          gaEvent({
            category: 'global_search',
            action: 'Клик',
            channel_name: channel.name,
            query: searchQuery,
          })
        "
      />
    </ContentSlider>
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import TilePosterChannel from 'src/components/ui/tiles/TilePosterChannel.vue';
import { getChannelLogo, getChannelTitle } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';

@Component({
  components: { TilePosterChannel, ContentSlider },
})
export default class SearchResultsChannels extends SequoiaComponent {
  get searchQuery() {
    return selectors.search.querySelector(this.$store);
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get hasChannels() {
    return this.channels?.length;
  }

  get channels() {
    return Object.values(selectors.tvChannels.filteredChannelsSelector(this.$store, null));
  }

  get channelsMapped() {
    if (this.channels.length) {
      return Object.values(this.channels).map((channel) => {
        return {
          id: channel?.id,
          name: this.getChannelTitle(channel),
          image: {
            src: channel ? this.getChannelLogo(channel) : '',
            width: 205, // the largest width for all dimensions (on mobile L)
            height: 115, // the largest height for all dimensions (on mobile L)
          },
          inFavorites: !!this.favoriteChannels[channel?.id],
          currentProgram:
            selectors.tvEpg.programForChannelSelector({
              store: this.$store,
              channelId: channel?.id,
              isLive: false,
              programIndex: selectors.tvEpg.liveProgramIndexForChannelSelector(
                this.$store,
                channel?.id
              ),
            }) || {},
        };
      });
    }
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  async toggleChannelFavorite(id: string | undefined) {
    if (id) {
      actions.metrics.setGaParams(this.$store, {
        category: 'global_search',
        control_type: 'mouse',
      });
      await actions.tvChannels.toggleChannelFavorite(this.$store, id);
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$store.vod.sources[_vm.source])?_c('section',{staticClass:"search-results-vod",class:_vm.hasTitles ? 'pb-64 pb-tablet-48 pb-mobile-48' : 'pb-48 pb-tablet-32 pb-mobile-32'},[_c('h3',{staticClass:"with-side-padding",domProps:{"innerHTML":_vm._s(_vm.titleTranslation)}}),(!_vm.isLoaded)?_c('LoaderSpinner'):[(!_vm.hasTitles)?_c('div',{staticClass:"with-side-padding"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('nothing_found'))}})]):_c('ContentSlider',{attrs:{"type":"adaptive","theme-forced":_vm.theme,"data-cy":`search-results-vod-${_vm.source}`},on:{"gaScroll":function($event){return _vm.gaEvent({
          category: 'global_search',
          action: 'Скролл стрипа',
          strip_name: _vm.title,
        })}}},_vm._l((_vm.$store.vod.sources[_vm.source].titles),function(title,i){return _c('TilePoster',{key:title.id + i.toString(),ref:"tilePoster",refInFor:true,staticClass:"col-tablet-2 col-mobile-2",class:_vm.source === 'archive' ? 'col-desktop-3' : 'col-desktop-2',attrs:{"countries":title.countries,"duration":title.duration,"genres":title.genres,"height-proportion":_vm.posterHeightProportion(_vm.source),"image":title.posters ? title.posters[0].path : '',"link":_vm.preparePosterLink(title, _vm.source),"logo":_vm.getTitleLogo(title),"orientation":_vm.source === 'archive' ? 'landscape' : 'portrait',"original-title":title.originalTitle,"rating-imdb":title.ratingImdb,"rating-kinopoisk":title.ratingKp,"source":_vm.source,"title":title.title,"title-branding-methods":title.brandingMethods,"width-proportion":_vm.posterWidthProportion(_vm.source),"years":title.years},on:{"click":function($event){return _vm.gaEvent({
            category: 'global_search',
            action: 'Клик',
            title_id: title.id,
            vod_name: _vm.source,
            query: _vm.searchQuery,
          })}}})}),1)]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section
    v-if="$store.vod.sources[source]"
    class="search-results-vod"
    :class="hasTitles ? 'pb-64 pb-tablet-48 pb-mobile-48' : 'pb-48 pb-tablet-32 pb-mobile-32'"
  >
    <h3 class="with-side-padding" v-html="titleTranslation" />

    <LoaderSpinner v-if="!isLoaded" />

    <template v-else>
      <div v-if="!hasTitles" class="with-side-padding">
        <p v-html="getTranslation('nothing_found')" />
      </div>

      <ContentSlider
        v-else
        type="adaptive"
        :theme-forced="theme"
        :data-cy="`search-results-vod-${source}`"
        @gaScroll="
          gaEvent({
            category: 'global_search',
            action: 'Скролл стрипа',
            strip_name: title,
          })
        "
      >
        <TilePoster
          v-for="(title, i) in $store.vod.sources[source].titles"
          ref="tilePoster"
          :key="title.id + i.toString()"
          class="col-tablet-2 col-mobile-2"
          :class="source === 'archive' ? 'col-desktop-3' : 'col-desktop-2'"
          :countries="title.countries"
          :duration="title.duration"
          :genres="title.genres"
          :height-proportion="posterHeightProportion(source)"
          :image="title.posters ? title.posters[0].path : ''"
          :link="preparePosterLink(title, source)"
          :logo="getTitleLogo(title)"
          :orientation="source === 'archive' ? 'landscape' : 'portrait'"
          :original-title="title.originalTitle"
          :rating-imdb="title.ratingImdb"
          :rating-kinopoisk="title.ratingKp"
          :source="source"
          :title="title.title"
          :title-branding-methods="title.brandingMethods"
          :width-proportion="posterWidthProportion(source)"
          :years="title.years"
          @click="
            gaEvent({
              category: 'global_search',
              action: 'Клик',
              title_id: title.id,
              vod_name: source,
              query: searchQuery,
            })
          "
        />
      </ContentSlider>
    </template>
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { getChannelLogo } from 'src/utils/channel';
import { prepareTitleLink } from 'src/utils/vod';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { TChannelEnhanced } from 'src/api/channels/types';

@Component({
  components: {
    TilePoster,
    LoaderSpinner,
    ContentSlider,
  },
})
export default class SearchResultsVod extends SequoiaComponent {
  @Prop({ required: true })
  source!: string;

  @Prop({ default: false })
  isLoaded?: boolean;

  get searchQuery() {
    return selectors.search.querySelector(this.$store);
  }

  get titleTranslation() {
    return this.source === 'archive'
      ? this.getTranslation(this.source)
      : this.customTitle
      ? this.getByLanguage(this.customTitle)
      : this.$store.vod.sources[this.source]
      ? this.$store.vod.sources[this.source].id
      : '';
  }

  get hasTitles() {
    return (
      this.$store.vod.sources[this.source]?.titles &&
      this.$store.vod.sources[this.source].titles?.length
    );
  }

  get customTitle() {
    return this.$store.vod.sources[this.source]?.customTitle || '';
  }

  getTitleLogo(title: TVODTitlePreviewEnhanced) {
    return title?.availableOnTvChannels
      ? getChannelLogo(this.getChannelById(title?.availableOnTvChannels[0].channelId))
      : this.getSourceLogoUrl(this.source);
  }

  posterWidthProportion(source: string) {
    return selectors.vod.posterWidthProportionSelector(this.$store, source);
  }

  posterHeightProportion(source: string) {
    return selectors.vod.posterHeightProportionSelector(this.$store, source);
  }

  preparePosterLink(title: TVODTitlePreviewEnhanced, sourceId: string) {
    return prepareTitleLink(title, sourceId);
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getSourceLogoUrl(sourceId: string) {
    return actions.vod.getSourceLogoUrl(this.$store, sourceId);
  }
}
</script>

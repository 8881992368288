<template>
  <div class="channel-collections">
    <SectionHeader
      class="with-side-padding"
      :text="getTranslation('channel_collections')"
      :icon="IconViewGrid"
    />
    <ContentSlider v-if="channelCollectionsToShow.length" :theme-forced="theme" type="adaptive">
      <TileCollection
        v-for="(collection, i) in channelCollectionsToShow"
        :key="collection.slug + i"
        class="col-desktop-4 col-tablet-4 col-mobile-4"
        :width-proportion="3"
        :height-proportion="2"
        :title="getByLanguage(collection.title) || ''"
        :posters="getChannelCollectionImages(collection)"
        @click="showChannelCollection(collection)"
      />
    </ContentSlider>
  </div>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { actions } from 'src/store/actions';
import { TChannelCollection } from 'src/store/channel-collections/types';
import IconViewGrid from 'src/svg/view-grid.svg';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import TileCollection from 'src/components/ui/tiles/TileCollection.vue';
import { getChannelCollectionImages } from 'src/utils/channel-collections';
import { selectors } from 'src/store/selectors';

@Component({
  components: {
    SectionHeader,
    TileCollection,
    ContentSlider,
  },
})
export default class ChannelCollections extends SequoiaComponent {
  IconViewGrid = IconViewGrid;

  get channelCollectionsToShow() {
    return selectors.channelCollections.channelCollectionsToShowOnMainSelector(this.$store);
  }

  getChannelCollectionImages(collection: TChannelCollection) {
    return getChannelCollectionImages(collection);
  }

  async showChannelCollection(collection: TChannelCollection) {
    await actions.channelCollections.showCollection(this.$store, collection.slug);
    this.gaEvent({
      category: 'homescreen',
      action: 'Клик по баннеру',
      banner_id: collection.slug,
      strip_name: this.getTranslation('channel_collections'),
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.channel-collections {
  .tile-collection {
    margin-bottom: 24px;

    @include mobile-and-tablet {
      margin-bottom: 16px;
    }
  }
}
</style>

<template>
  <div class="vod-collections">
    <LoaderSpinner v-if="showLoader" />

    <section
      v-else
      v-for="(collection, i) in collections"
      :key="collection.id + i"
      :data-id="collection.id"
      class="pb-64 pb-tablet-48 pb-mobile-48 overflow-hidden"
      data-cy="vod-collections"
      :class="{ 'pb-16': i === collections.length - 1 }"
    >
      <SectionHeaderLink
        class="with-side-padding"
        link=""
        :text="collection.caption"
        @click.native="
          showCollection(collection.id);
          gaEvent({
            category: 'homescreen',
            action: 'Клик по заголовку стрипа',
            collection_name: collection.caption,
          });
        "
      />

      <HomeContentSlider
        v-if="collection.titlesList && collection.titlesList.length"
        :theme-forced="theme"
        type="adaptive"
        @gaScroll="gaScroll(collection.caption, collection.vodSource)"
      >
        <TilePoster
          v-for="(title, i) in collection.titlesList"
          :key="title.id + i"
          class="col-desktop-2 col-tablet-2 col-mobile-2"
          :countries="title.countries"
          :duration="title.duration"
          :genres="title.genres"
          :height-proportion="title.heightProportion"
          :image="title.posters ? title.posters[0].path : ''"
          :image-provider="title.posters ? title.posters[0].type : ''"
          :link="title.link"
          :logo="getTitleLogo(title, collection)"
          :original-title="title.originalTitle"
          :rating-imdb="title.ratingImdb"
          :rating-kinopoisk="title.ratingKp"
          :source="title.sourceId"
          :title="title.title"
          :title-branding-methods="title.brandingMethods"
          :width-proportion="title.widthProportion"
          :years="title.years"
          @click="
            gaEvent({
              category: 'homescreen',
              action: 'Клик по тайтлу в стрипе',
              strip_name: collection.caption,
              title_id: title.id,
            })
          "
        />
        <div
          v-if="checkForViewMoreButton(collection)"
          class="col-desktop-2 col-tablet-2 col-mobile-2"
        >
          <div
            class="tile-view-more-wrapper"
            :style="{ paddingTop: `${posterAspectRatio(collection.vodSource) * 100}%` }"
          >
            <TileViewMore
              class="tile-view-more"
              link=""
              :text="getTranslation('show_more')"
              @click="
                showCollection(collection.id);
                gaEvent({
                  category: 'homescreen',
                  action: 'Клик по кнопке ”Показать еще”',
                  collection_name: collection.caption,
                });
              "
            />
          </div>
        </div>
      </HomeContentSlider>
    </section>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { VODHomeCollectionEnhanced } from 'src/store/vod/types';
import SectionHeaderLink from 'src/components/ui/SectionHeaderLink.vue';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import TileViewMore from 'src/components/ui/tiles/TileViewMore.vue';
import HomeContentSlider from 'src/components/home/HomeContentSlider.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { getChannelLogo } from 'src/utils/channel';
import { prepareTitleLink } from 'src/utils/vod';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getSeoBot } from 'src/utils/platform-detector';
import LoaderSpinner from '../ui/loader/LoaderSpinner.vue';
import { DEFAULT_NUMBER_OF_SLIDES } from 'src/constants';
import { v4 as uuidV4 } from 'uuid';

// import logger from 'src/utils/logger';
// const log = logger('vod-collectionss');

@Component({
  components: {
    HomeContentSlider,
    SectionHeaderLink,
    TilePoster,
    TileViewMore,
    LoaderSpinner,
  },
})
export default class VodCollections extends SequoiaComponent {
  uuid = uuidV4();

  collections: any[] = [];
  showLoader = true;

  @Watch('$store.vod.states.sourcesLoaded')
  async onVodSourcesLoadedChange(val: boolean) {
    if (val) {
      await this.loadCollections();
      this.setCollections();
    }
  }

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get listOfAllSourceIds() {
    return selectors.vod.listOfAllSourceIdsSelector(this.$store);
  }

  get vodSourcesLoaded() {
    return selectors.vod.sourcesLoadedSelector(this.$store);
  }

  get vodCollections() {
    return selectors.vod.collectionsSelector(this.$store);
  }

  async serverPrefetch() {
    if (getSeoBot()) {
      await this.loadCollections();
    }
  }

  async mounted() {
    if (this.vodSourcesLoaded) {
      await this.loadCollections();
      this.setCollections();
    }
  }

  setCollections() {
    setTimeout(() => {
      // getting VOD collections is a very costly operation since it could contain > 300 titles
      // wrapping it in setTimeout (even with 0ms delay) makes its execution start AFTER the code without any timeouts
      // thus we set the lower priority for this code -> so it will not block more important code
      // see https://levelup.gitconnected.com/understanding-settimeout-15c7de9e5fd6
      this.collections = this.vodCollections?.flatMap((collection) => {
        if (this.listOfAllSourceIds.includes(collection.vodSource)) {
          return this.prepareCollection(collection);
        } else {
          return [];
        }
      });
      this.showLoader = false;
    }, 0);
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getTitleLogo(title: TVODTitlePreviewEnhanced, collection: VODHomeCollectionEnhanced) {
    const channels = title.availableOnTvChannels;
    const channelId = channels && channels.length ? channels[0].channelId : '';
    const channel = this.getChannelById(channelId);
    return channel ? this.getChannelLogo(channel) : collection.logo;
  }

  loadCollections() {
    return actions.vod.loadCollections(this.$store);
  }

  posterWidthProportion(source: string) {
    return selectors.vod.posterWidthProportionSelector(this.$store, source);
  }

  posterHeightProportion(source: string) {
    return selectors.vod.posterHeightProportionSelector(this.$store, source);
  }

  posterAspectRatio(source: string) {
    return this.posterHeightProportion(source) / this.posterWidthProportion(source);
  }

  async showCollection(id: string) {
    await actions.vod.showCollection(this.$store, id, false);
  }

  prepareCollection(collection: VODHomeCollectionEnhanced) {
    return {
      ...collection,
      titlesList: collection.titlesList.slice(0, DEFAULT_NUMBER_OF_SLIDES - 1).map((title) => ({
        ...title,
        widthProportion: this.posterWidthProportion(collection.vodSource),
        heightProportion: this.posterHeightProportion(collection.vodSource),
        link: prepareTitleLink(title, collection.vodSource),
        logo: this.getTitleLogo(title, collection),
      })),
    };
  }

  checkForViewMoreButton(collection: VODHomeCollectionEnhanced) {
    const len = this.vodCollections?.find((c) => c.id === collection.id)?.titlesList?.length;
    return len && len > DEFAULT_NUMBER_OF_SLIDES;
  }

  gaScroll(caption: string, source: string) {
    this.gaEvent({
      category: 'homescreen',
      action: 'Скролл стрипа',
      strip_name: caption,
      vod_name: source,
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

<style lang="scss" scoped>
.vod-collections {
  .tile-view-more-wrapper {
    position: relative;
    flex: 1;
  }

  .tile-view-more {
    position: absolute;
    top: 0;
  }

  .tile-posters-mobile {
    display: flex;
    flex-wrap: nowrap;
    padding-top: 20px;
    padding-bottom: 100px;
    margin-top: -20px;
    margin-bottom: -100px;
    overflow: auto;

    > * {
      flex-shrink: 0;
    }
  }
}
</style>

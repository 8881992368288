<template>
  <div class="verify-session-step">
    <ImageWithHash
      class="background-desktop"
      src="/public/images/backgrounds/candy-gradient.png"
      alt=""
    />

    <div class="with-side-and-bottom-padding content">
      <h3 class="title" v-html="getTranslation('adult_modal_verify_session_title')" />
      <div class="body1 mb-24" v-html="getTranslation('adult_modal_verify_session')" />
      <div class="input-wrapper mb-16">
        <InputText
          ref="smscode"
          v-model="value"
          class="mb-8"
          :icon="bySms ? '' : 'visibility'"
          input-category="floating"
          autocomplete="off"
          required
          size="small"
          :label="getTranslation(bySms ? 'code_from_sms' : 'password')"
          :status="error.input ? 'error' : undefined"
          :message="error.input"
        />
        <ButtonDefault :disabled="!value" class="button" @click="next">
          <span v-html="getTranslation('next')" />
        </ButtonDefault>
        <p class="body2 color-light-error mt-4" v-text="error.common" />
      </div>

      <div v-if="bySms" class="mb-16">
        <p
          v-if="sendSmsSeconds > 0"
          class="color-light-secondary"
          v-html="sendCodeAgainTranslation"
        />
        <a
          v-else
          class="link action-colored"
          @click="sendSms"
          v-html="getTranslation('adult_modal_send_sms_code_again')"
        />
      </div>

      <a
        v-if="isPhoneVerified"
        class="link action-colored"
        @click="toggle"
        v-html="sendSmsCodeOrEnterPasswordTranslation"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Ref, Vue } from 'vue-property-decorator';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import { actions } from 'src/store/actions';
import InputText from 'src/components/ui/input/InputText.vue';
import * as api from 'src/api';
import { selectors } from 'src/store/selectors';
import {
  HTTP_CODES,
  RESEND_SMS_CODE_TIMEOUT,
  ADULT_ONBOARDING_PROFILE_CHANGE_TIMEOUT,
  ADULT_ONBOARDING_MAX_NUMBER_OF_TRY,
} from 'src/constants';
import { sleep } from 'src/utils';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';
import { cleanupPhone } from 'src/utils/phone';

@Component({
  components: { ImageWithHash, ButtonDefault, ProgressBar, InputText },
})
export default class VerifySession extends SequoiaComponent {
  value = '';
  bySms = false;
  error = {
    common: '',
    input: '',
  };
  sendSmsSeconds = 0;
  sendSmsInterval: NodeJS.Timeout | null = null;

  counter = 0;
  maxNumberOfTry = ADULT_ONBOARDING_MAX_NUMBER_OF_TRY;

  @Ref('smscode')
  refSmscode?: Vue;

  get profiles() {
    return (
      this.$store.account?.profiles?.filter((profile) => {
        return profile.restriction === 'adult';
      }) || []
    );
  }

  get isPhoneVerified() {
    return this.$store.account?.is_phone_verified;
  }

  get nextStep() {
    return selectors.adultOnboarding.nextStepSelector(this.$store);
  }

  get sendCodeAgainTranslation() {
    return this.getTranslation('send_code_again').replace(/%seconds%/g, `${this.sendSmsSeconds}`);
  }

  get sendSmsCodeOrEnterPasswordTranslation() {
    return !this.bySms
      ? this.getTranslation('adult_modal_send_sms_code')
      : this.getTranslation('adult_modal_enter_password');
  }

  mounted() {
    (this.refSmscode?.$refs.input as HTMLInputElement | undefined)?.focus();
  }

  beforeDestroy() {
    if (this.sendSmsInterval) {
      clearInterval(this.sendSmsInterval);
    }
  }

  async next() {
    const profile = this.profiles[0];

    try {
      this.$emit('set-loader', true);
      if (this.bySms) {
        await this.verifySmsCode();
      } else {
        await this.managingByPassword();
      }
      this.$emit('set-loader', false);

      if (this.nextStep) {
        actions.adultOnboarding.setCurrentStep(this.$store, this.nextStep);
        actions.adultOnboarding.setNextStep(this.$store, '');
      } else if (profile) {
        try {
          this.$emit('set-loader', true);
          if (profile.pin_required) {
            await actions.profile.update(this.$store, profile.id, {
              data: { Name: profile.name, Pin: '', 'Restriction.Id': 'adult' },
            });
          }

          await this.changeProfile(profile.id, '');
          this.$emit('set-loader', false);
        } catch (err) {
          this.error.common = err.message;
        }
      }
    } catch (err) {
      this.error.input = err.message;
    }

    this.gaEvent({
      category: 'adult_profile',
      action: 'adult_modal_verify_session ' + this.bySms ? 'sms' : 'password',
    });
  }

  async changeProfile(id: string, pin = '') {
    try {
      await actions.profile.change(this.$store, {
        data: {
          Id: id,
          Pin: pin,
        },
      });

      this.$emit(
        'showNotification',
        this.getTranslation('adult_alert_pin_disabled_title'),
        this.getTranslation('adult_alert_pin_disabled'),
        true
      );

      await this.$router.push('/channels/now');
    } catch (err) {
      if (err.code === HTTP_CODES.BAD_REQUEST && this.counter < this.maxNumberOfTry) {
        this.counter++;
        await sleep(ADULT_ONBOARDING_PROFILE_CHANGE_TIMEOUT);
        await this.changeProfile(id, pin);
      }
      if (this.counter === this.maxNumberOfTry) {
        this.error.common = this.getTranslation('adult_onboarding_bad_request');
      }
    }
  }

  async managingByPassword() {
    await api.profile.managing({
      data: {
        email: this.$store.account?.user.login,
        password: this.value,
      },
    });
  }

  async sendSms() {
    this.error.input = '';
    this.sendSmsSeconds = RESEND_SMS_CODE_TIMEOUT;
    try {
      await api.auth.contactsVerification.startWithAccountSession({
        data: {
          type: 'phone',
          contacts: cleanupPhone(
            this.$store.account?.user?.phone,
            this.$store.authAndReg.usePlusInPhoneMethods.registration
          ),
          reason: 'default',
        },
      });
    } catch (err) {
      this.error.common = err.message;
    }
  }

  async verifySmsCode() {
    const { sessionToken } = await api.auth.contactsVerification.verify({
      data: {
        type: 'phone',
        contacts: cleanupPhone(
          this.$store.account?.user?.phone,
          this.$store.authAndReg.usePlusInPhoneMethods.registration
        ),
        code: this.value,
      },
    });
    await api.profile.managingWithVerifiedContacts({
      params: {
        'contacts-session-token': sessionToken,
      },
      data: {
        login: this.$store.account?.user.login,
      },
    });
  }

  toggle() {
    this.value = '';
    this.bySms = !this.bySms;

    if (this.bySms && this.sendSmsSeconds === 0) {
      this.sendSms();

      this.sendSmsInterval = setInterval(() => {
        if (this.sendSmsSeconds <= 0) {
          if (this.sendSmsInterval) {
            clearInterval(this.sendSmsInterval);
          }
          return;
        }
        this.sendSmsSeconds--;
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.verify-session-step {
  .input-wrapper {
    .button {
      width: 100%;
    }
  }
  @include desktop {
    .input-wrapper {
      max-width: 288px;

      .button {
        width: 100%;
      }
    }
  }
}
</style>

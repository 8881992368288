var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vod-collections"},[(_vm.showLoader)?_c('LoaderSpinner'):_vm._l((_vm.collections),function(collection,i){return _c('section',{key:collection.id + i,staticClass:"pb-64 pb-tablet-48 pb-mobile-48 overflow-hidden",class:{ 'pb-16': i === _vm.collections.length - 1 },attrs:{"data-id":collection.id,"data-cy":"vod-collections"}},[_c('SectionHeaderLink',{staticClass:"with-side-padding",attrs:{"link":"","text":collection.caption},nativeOn:{"click":function($event){_vm.showCollection(collection.id);
        _vm.gaEvent({
          category: 'homescreen',
          action: 'Клик по заголовку стрипа',
          collection_name: collection.caption,
        });}}}),(collection.titlesList && collection.titlesList.length)?_c('HomeContentSlider',{attrs:{"theme-forced":_vm.theme,"type":"adaptive"},on:{"gaScroll":function($event){return _vm.gaScroll(collection.caption, collection.vodSource)}}},[_vm._l((collection.titlesList),function(title,i){return _c('TilePoster',{key:title.id + i,staticClass:"col-desktop-2 col-tablet-2 col-mobile-2",attrs:{"countries":title.countries,"duration":title.duration,"genres":title.genres,"height-proportion":title.heightProportion,"image":title.posters ? title.posters[0].path : '',"image-provider":title.posters ? title.posters[0].type : '',"link":title.link,"logo":_vm.getTitleLogo(title, collection),"original-title":title.originalTitle,"rating-imdb":title.ratingImdb,"rating-kinopoisk":title.ratingKp,"source":title.sourceId,"title":title.title,"title-branding-methods":title.brandingMethods,"width-proportion":title.widthProportion,"years":title.years},on:{"click":function($event){return _vm.gaEvent({
            category: 'homescreen',
            action: 'Клик по тайтлу в стрипе',
            strip_name: collection.caption,
            title_id: title.id,
          })}}})}),(_vm.checkForViewMoreButton(collection))?_c('div',{staticClass:"col-desktop-2 col-tablet-2 col-mobile-2"},[_c('div',{staticClass:"tile-view-more-wrapper",style:({ paddingTop: `${_vm.posterAspectRatio(collection.vodSource) * 100}%` })},[_c('TileViewMore',{staticClass:"tile-view-more",attrs:{"link":"","text":_vm.getTranslation('show_more')},on:{"click":function($event){_vm.showCollection(collection.id);
              _vm.gaEvent({
                category: 'homescreen',
                action: 'Клик по кнопке ”Показать еще”',
                collection_name: collection.caption,
              });}}})],1)]):_vm._e()],2):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
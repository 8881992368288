<template>
  <button
    ref="tag"
    type="button"
    class="tag"
    :class="theme"
    :data-cy="dataCyPrefixed"
    @click="onClick"
    @focus="onFocus"
  >
    <span v-if="hash" class="hash">#</span>
    <span class="text" v-html="text" />
  </button>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class TagSequoia extends SequoiaComponent {
  @Prop({ required: true })
  text!: string;

  @Prop({ default: false })
  hash!: boolean;

  @Prop()
  dataCy?: string;

  @Ref('tag')
  refTag?: HTMLButtonElement;

  get dataCyPrefixed() {
    return this.dataCy ? `tag-${this.dataCy}` : '';
  }

  onClick(e: MouseEvent) {
    this.$emit('click', e, this.refTag);
  }

  onFocus(e: MouseEvent) {
    this.$emit('focus', e, this.refTag);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.tag {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  text-align: left;
  overflow-wrap: anywhere;
  border-radius: 8px;

  @include devices-with-hover {
    min-height: 32px;

    &.light,
    &.dark {
      &:hover,
      &:active {
        background-color: var(--c-overlay-brand-custom-2);
      }
    }
  }

  .hash {
    padding-right: 4px;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    color: var(--c-light-font-primary);
    background-color: var(--alpha-light-3);

    @include devices-with-hover {
      &:active {
        .text {
          color: var(--c-light-brand);
        }
      }
    }

    .hash {
      color: var(--c-light-font-tertiary);
    }
  }

  &.dark {
    color: var(--c-dark-font-primary);
    background-color: var(--alpha-dark-3);

    @include devices-with-hover {
      &:active {
        .text {
          color: var(--c-dark-brand);
        }
      }
    }

    .hash {
      color: var(--c-dark-font-tertiary);
    }
  }
}
</style>

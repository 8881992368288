<template>
  <div v-if="banners.length" class="banner-hero-image">
    <div v-for="banner in banners" :key="banner.deviceType" class="banner-wrap">
      <LazyImage
        v-if="oneSlide"
        :src="banner.image.src"
        :width="banner.image.width"
        :height="banner.image.height"
        :class="banner.className"
        class="image"
      />
      <img
        :data-src="banner.src1x"
        :data-srcset="banner.srcSet"
        :src="shouldRenderBannerImage(banner) ? banner.src1x : BLANK_IMAGE"
        :srcset="shouldRenderBannerImage(banner) ? banner.srcSet : ''"
        :class="banner.className"
        :data-device="banner.deviceType"
        :width="banner.image.width + 'px'"
        :height="banner.image.height + 'px'"
        class="image"
        alt=""
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import LazyImage from 'src/components/LazyImage.vue';
import { getResizedImageUrl } from 'src/utils/images';
import { BLANK_IMAGE } from 'src/constants';
import { getDeviceType } from 'src/utils/platform-detector';
import { TBannerHeroImage } from 'src/components/ui/banners/types';
import AddResizeListener from 'src/mixins/AddResizeListener';

interface TBanner {
  image: TBannerHeroImage;
  src1x: string;
  srcSet: null | string;
  className: string;
  deviceType: string;
}

@Component({
  components: { LazyImage },
})
export default class BannerHeroImage extends mixins(SequoiaComponent, AddResizeListener) {
  BLANK_IMAGE = BLANK_IMAGE;
  deviceType: 'mobile-s' | 'mobile-l' | 'desktop' | 'unknown' = 'unknown';
  usedDeviceTypes: Record<string, boolean> = {};

  @Prop({ required: true })
  imageMobileSmall!: TBannerHeroImage;

  @Prop({ required: true })
  imageMobileLarge!: TBannerHeroImage;

  @Prop({ required: true })
  imageDesktop!: TBannerHeroImage;

  @Prop()
  oneSlide?: boolean;

  @Prop()
  preload?: boolean;

  get banners(): TBanner[] {
    return [
      {
        image: this.imageDesktop,
        src1x: this.getSrc1x(this.imageDesktop),
        srcSet: null,
        className: 'hero-hidden-mobile',
        deviceType: 'desktop',
      },
      {
        image: this.imageMobileLarge,
        src1x: this.getSrc1x(this.imageMobileLarge),
        srcSet: this.getSrcSet(this.imageMobileLarge),
        className: 'hero-visible-mobile-l',
        deviceType: 'mobile-l',
      },
      {
        image: this.imageMobileSmall,
        src1x: this.getSrc1x(this.imageMobileSmall),
        srcSet: this.getSrcSet(this.imageMobileSmall),
        className: 'hero-visible-mobile-s',
        deviceType: 'mobile-s',
      },
    ];
  }

  get deviceTypeOnServer() {
    switch (getDeviceType()) {
      case 'phone':
        return 'mobile-s';
      case 'tablet':
        return 'mobile-l';
      default:
        return 'desktop';
    }
  }

  get deviceTypeOnClient() {
    if (this.windowWidth < 480) {
      return 'mobile-s';
    } else if (this.windowWidth >= 480 && this.windowWidth < 960) {
      return 'mobile-l';
    } else {
      return 'desktop';
    }
  }

  @Watch('windowWidth')
  onChangeWindowWidth() {
    this.deviceType = this.deviceTypeOnClient;
    this.usedDeviceTypes[this.deviceType] = true;
  }

  created() {
    if (process.env.VUE_ENV === 'server') {
      this.deviceType = this.deviceTypeOnServer;
    }
  }

  async mounted() {
    this.deviceType = this.deviceTypeOnClient;
    this.usedDeviceTypes[this.deviceType] = true;
  }

  getSrc1x(image: TBannerHeroImage) {
    return getResizedImageUrl(image.src, image.width, image.height);
  }

  getSrc2x(image: TBannerHeroImage) {
    return getResizedImageUrl(image.src, image.width * 2, image.height * 2);
  }

  getSrcSet(image: TBannerHeroImage) {
    const src1x = this.getSrc1x(image);
    const src2x = this.getSrc2x(image);
    return `${src1x} 1x, ${src2x} 2x`;
  }

  shouldRenderBannerImage(banner: TBanner) {
    if (!this.preload) {
      return true;
    }
    const isBannerRendered = this.usedDeviceTypes[banner.deviceType];
    return this.deviceType === banner.deviceType || isBannerRendered;
  }
}
</script>

<style lang="scss" scoped>
.banner-hero-image img {
  position: absolute;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: var(--c-light-200);
}

// special case for banners
$mobile-s-max: 479px;
$mobile-l-min: 480px;
$mobile-l-max: 960px;

@mixin mobile-s {
  @media (max-width: $mobile-s-max) {
    @content;
  }
}

@mixin mobile-l {
  @media (min-width: $mobile-l-min) and (max-width: $mobile-l-max) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile-l-max) {
    @content;
  }
}

.hero-hidden-mobile {
  @include mobile {
    display: none !important;
  }
}

.hero-visible-mobile-s {
  display: none;

  @include mobile-s {
    display: inherit !important;
  }
}

.hero-visible-mobile-l {
  display: none;

  @include mobile-l {
    display: inherit !important;
  }
}
</style>

<template>
  <section
    v-if="queries && queries.length"
    class="recent-queries pb-48 pb-tablet-32 pb-mobile-32 mt-0"
    data-cy="recent-queries"
  >
    <div class="inner">
      <div class="body1 color-secondary" v-html="getTranslation('gsearch_recent_searches')" />
      <div class="tags">
        <TagSequoia
          v-for="(query, i) in queries"
          :key="i + query"
          :text="query"
          :data-cy="`recent-query-${i}`"
          @click="onClick(query)"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import TagSequoia from 'src/components/ui/TagSequoia.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    TagSequoia,
  },
})
export default class RecentQueries extends SequoiaComponent {
  @Prop()
  queries?: Array<string>;

  onClick(query: string) {
    actions.search.setQuery(this.$store, query);
    this.$emit('submitSearch', 'Недавний поиск');
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/tags';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.recent-queries {
  .inner {
    display: flex;
    margin-left: 24px;

    @include mobile-and-tablet {
      flex-direction: column;
      margin-left: 16px;
    }

    @include mobile {
      margin-left: 0;
    }
  }

  .body1 {
    margin-right: 16px;
    line-height: 2.5;

    @media (min-width: #{$desktop-s-min}) {
      flex-basis: 180px;
      flex-shrink: 0;
    }

    @include devices-with-hover {
      line-height: 2;
    }
  }
}
</style>

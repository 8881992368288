<template>
  <button
    type="button"
    class="button view-more"
    :class="[{ 'full-width': fullWidth }, $store.theme]"
    @click="goToMore"
  >
    <span class="body2" v-html="text" />
    <IconSVG :svg="IconArrowRight" :size="16" />
  </button>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import IconArrowRight from 'src/svg/arrow-right.svg';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: { IconSVG },
})
export default class ButtonViewMore extends Vue {
  IconArrowRight = IconArrowRight;

  @Prop({ required: true })
  text!: string;

  @Prop()
  link?: string;

  @Prop({ default: false })
  fullWidth?: boolean;

  goToMore() {
    this.$emit('click');
    if (this.link) {
      this.$router.push(this.link);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button.view-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  transition: var(--ease-out) 0.15s;

  &.full-width {
    width: 100%;
  }

  .icon {
    position: relative;
    left: 4px;
    transition: all var(--ease-out) 0.15s;
  }

  @include devices-with-hover {
    &:hover,
    &.active {
      .icon {
        left: 8px;
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    background-color: var(--alpha-light-3);

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-light-4);
      }
    }

    &:active {
      background-color: var(--alpha-light-5);
    }
  }

  &.dark {
    background-color: var(--alpha-dark-3);

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-4);
      }
    }

    &:active {
      background-color: var(--alpha-dark-5);
    }
  }
}
</style>

<template>
  <TileThumbnail
    class="col-desktop-2 col-tablet-2 col-mobile-2"
    :title="thumb.title"
    :description="thumb.description"
    :link="thumb.link"
    :image="thumb.image"
    :duration="thumb.duration"
    :timeline-position="thumb.timelinePosition"
    @click.native="onClick(thumb.id, thumb.link)"
  />
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import TileThumbnail from 'src/components/ui/tiles/TileThumbnail.vue';
import { THomeHistoryTile } from 'src/store/home/types';
import { actions } from 'src/store/actions';

@Component({
  components: {
    TileThumbnail,
  },
})
export default class HomeHistoryTile extends SequoiaComponent {
  @Prop()
  thumb!: THomeHistoryTile;

  onClick(id: string, link: string) {
    actions.vod.setIsSavedPause(this.$store, true);
    this.gaEvent({
      category: 'homescreen',
      action: 'Клик по тайтлу в стрипе',
      strip_name: this.getTranslation('history'),
      title_id: id,
      vod_name: link.split('/')[2],
    });
  }
}
</script>

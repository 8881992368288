var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"search-results-channels",class:_vm.hasChannels ? 'pb-64 pb-tablet-48 pb-mobile-48' : 'pb-48 pb-tablet-32 pb-mobile-32',attrs:{"data-cy":"search-results-channels"}},[_c('h3',{staticClass:"with-side-padding",domProps:{"innerHTML":_vm._s(_vm.getTranslation('channels'))}}),(!_vm.hasChannels)?_c('div',{staticClass:"with-side-padding"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('channels_were_not_found'))}})]):_c('ContentSlider',{attrs:{"type":"adaptive","theme-forced":_vm.theme,"same-width-slides":true},on:{"gaScroll":function($event){_vm.gaEvent({
        category: 'global_search',
        action: 'Скролл стрипа',
        strip_name: _vm.getTranslation('channels'),
      })}}},_vm._l((_vm.channelsMapped),function(channel,i){return _c('TilePosterChannel',{key:channel.id + i.toString(),staticClass:"col-desktop-2 col-tablet-2 col-mobile-2",attrs:{"channel-id":channel.id,"channel-name":channel.name,"channel-image":channel.image,"show-favorites":!_vm.isAnonymous,"is-in-favorites":channel.inFavorites,"program-title":channel.currentProgram ? channel.currentProgram.title : '',"program-start-h-m":channel.currentProgram ? channel.currentProgram.startTimeHM : '',"program-end-h-m":channel.currentProgram ? channel.currentProgram.endTimeHM : '',"data-cy":"search"},on:{"toggleFavorite":function($event){return _vm.toggleChannelFavorite(channel.id)},"click":function($event){return _vm.gaEvent({
          category: 'global_search',
          action: 'Клик',
          channel_name: channel.name,
          query: _vm.searchQuery,
        })}}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-page"},[_c('div',{staticClass:"home-page-inner"},[(_vm.$store.common.isProfileLoading)?_c('LoaderSpinner'):[_c('HomeBanners',{staticClass:"pb-64 pb-tablet-48 pb-mobile-48",on:{"callAdultOnboardingModal":_vm.callAdultOnboardingModal}}),_c('div',{staticClass:"container-1440"},[(
            _vm.channelCollectionsDataStateLoaded && !_vm.hasChannelCollectionsToShow && _vm.wlDetector().isKt
          )?_c('SuggestedChannels',{staticClass:"pb-48 pb-tablet-32 pb-mobile-32"}):_vm._e(),(!_vm.isAnonymous)?_c('HomeHistory',{key:_vm.refreshKey,staticClass:"pb-48 pb-tablet-32 pb-mobile-32"}):_vm._e(),(
            _vm.channelCollectionsDataStateLoaded &&
            !_vm.hasChannelCollectionsToShow &&
            !_vm.wlDetector().isKt
          )?_c('SuggestedChannels',{staticClass:"pb-48 pb-tablet-32 pb-mobile-32"}):_vm._e(),(!_vm.isAnonymous)?_c('RecentChannels',{staticClass:"pb-48 pb-tablet-32 pb-mobile-32"}):_vm._e(),_c('TilesVodNavigationGroup',{staticClass:"with-side-padding pb-48 pb-tablet-32 pb-mobile-16",attrs:{"data-cy":"home","show-header":false},on:{"gaTileClick":_vm.gaTileClick}}),(_vm.channelCollectionsDataStateLoaded && _vm.hasChannelCollectionsToShow)?_c('ChannelCollections',{staticClass:"pb-48 pb-tablet-32 pb-mobile-32"}):_c('FeaturedChannels',{staticClass:"pb-64 pb-tablet-48 pb-mobile-48",on:{"gaViewMore":_vm.gaViewMore,"gaFeaturedClick":_vm.gaFeaturedClick,"gaScroll":_vm.gaScroll}}),(_vm.showVodCollections)?_c('VodCollections'):_c('VodRecommendations',{attrs:{"gaEventCategory":"homescreen"}}),(_vm.isModalOpen && (_vm.currentChannelCollection || _vm.isModalChannelOpen))?_c('ModalSequoia',{attrs:{"type":"responsive","theme-forced":"dark","show-title":false,"title":_vm.modalChannelAndCollectionTitle,"with-button-back":_vm.showButtonBackInModalChannelAndCollection},on:{"close":_vm.hideModalChannelAndCollection,"back":_vm.handleModalChannelAndCollectionStepBack}},[(!_vm.isModalChannelOpen)?_c('ChannelCollectionDetails',{attrs:{"collection":_vm.currentChannelCollection}}):_vm._e(),(_vm.isModalChannelOpen)?_c('ChannelDetails',{attrs:{"channel":_vm.modalChannel}}):_vm._e()],1):_vm._e(),(_vm.isModalOpen && _vm.currentVodCollection)?_c('ModalSequoia',{attrs:{"type":"responsive","theme-forced":"dark","show-title":false,"title":_vm.currentVodCollection.caption},on:{"close":_vm.hideModalVodCollection}},[_c('VodCollectionDetails',{attrs:{"collection":_vm.currentVodCollection}})],1):_vm._e()],1)],(_vm.showOnboarding)?_c('ModalSequoia',{attrs:{"type":"fixed","size":"medium"},on:{"close":function($event){_vm.showOnboarding = false}}},[_c('AdultModal',{on:{"close":function($event){_vm.showOnboarding = false}}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }